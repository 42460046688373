.promo {
   padding: var(--standart-block-padding);
   background-color: var(--background-modal-secondary);
   width: 551px;
   border: 1px solid var(--border-base-default);
   border-radius: var(--radius-24);

   .imgTexts {
      display: flex;
      gap: 12px;

      img {
         width: 58px;
         height: 58px;
      }

      .textBlock {
         display: flex;
         flex-direction: column;
         gap: 4px;

         .promoHeader {
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: -0.48px;
            color: var(--text-base-primary);
         }

         .promoDescription {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.28px;
            color: var(--text-base-secondary);
         }
      }
   }
}