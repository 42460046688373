@use '../../../common/styles/mixins';
@import 'src/common/styles/_adaptive.scss';

.listBalancesDropdown {
    .menu {
        width: var(--list-balances-dropdown-width);
        padding: 24px;
        background-color: var(--background-modal-primary);
        border: 1px solid var(--border-base-default);
        border-radius: var(--radius-40);
        cursor: auto;

        .tippy {
            position: absolute;
            top: -10px;
            right: 50%;
            transform: translateX(50%);
        }

        .tabs {
            width: 100%;
        }
    }
}

.currencyContent {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .currencyElement {
        height: 48px;
        display: flex;
        justify-content: space-between;
        border-radius: var(--radius-100);
        padding-right: 16px;
        align-items: center;

        &:hover {
            border: 1px solid var(--border-base-default);
            cursor: pointer;

            .currencyCode {
                border-left: none;
                border-top: none;
                border-bottom: none;
            }
        }

        .currencyCode, .amount {
            color: var(--text-base-primary);

            .checkedAccountImg {
                margin-right: 8px;
            }
        }

        .currencyCode {
            @include mixins.flexCenter();
            gap: 4px;
            height: 100%;
            width: 84px;
            border: 1px solid var(--border-base-default);
            border-radius: var(--radius-100);
            background-color: var(--background-surface-elevation-2);
        }
    }
}

@media #{$mobile} {
    .listBalancesDropdown {
        width: var(--list-balances-dropdown-width-mobile);

        .menu {
            padding: 16px;
        }

        .scrollableContent {
            max-height: 360px;
            overflow-y: scroll;
            box-sizing: content-box; 
        }
    }
}