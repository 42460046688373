@use 'src/common/styles/mixins';

.uid {
    display: flex;
    justify-content: space-between;
}

.title {
    @include mixins.title30();
    margin-bottom: 0;
    padding: 24px;
}

.backgroundSurfaceElevation1 {
    background-color: var(--background-surface-elevation-1);
}

.backgroundSurfaceElevation2 {
    background-color: var(--background-surface-elevation-2);
}

.paginationTableContainer {
    margin-top: 40px;
    @include mixins.flex($justifyContent: center);
}