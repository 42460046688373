@use 'src/common/styles/mixins';
@import 'src/common/styles/_adaptive.scss';

.faqContainer {
    --gap-question: 16px;
    height: 100%;

    .pageHeader {
        margin-bottom: var(--content-grid-padding);
    }
}

@media #{$mobile} {
    .faqContainer {
        padding: 16px;
    }
}


