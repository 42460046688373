@use 'src/common/styles/mixins';

.addWallet {
    h5 {
      @include mixins.text16_mobile_bonus;
    }

    .currencyContainer {
       @include mixins.flex($direction: column);
       gap: 16px;
    }

    .buttonGroup {
       @include mixins.buttonGroup();
    }
}

.renderMessages {
    width: 100%;
}