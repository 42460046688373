.filtersForTable {
    background-color: var(--background-surface-elevation-2);
    border-radius: var(--radius-24);
    margin: var(--standart-block-padding);
    padding: 16px;

    .filtersList {
        display: grid;
        grid-template-columns: repeat(6, minmax(0, 1fr));
        gap: var(--standart-block-padding);
    }

    .bottomButtons {
        margin-top: var(--standart-block-padding);
        display: flex;
        justify-content: right;
        align-items: center;
        gap: 15px;
    }
}