@use 'src/common/styles/mixins';

.security {
    width: 100%;

    .title {
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -0.48px;
        margin-bottom: var(--standart-block-padding);
        font-size: 24px;
    }



    .input {
        margin-bottom: var(--standart-block-padding);
    }


}