@import 'https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0';
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css";

.dropdown.show .btn::after {
  transform:rotate(180deg);
}

.horizontal-scroll-container {
  display: flex;           /* Размещаем дочерние элементы в строку */
  overflow-x: auto;        /* Добавляем горизонтальную прокрутку */
  white-space: nowrap;     /* Запрещаем перенос строк внутри контейнера */
}

.horizontal-scroll-container > div {
  margin-right: 20px;      /* Пространство между элементами (не обязательно) */
}

.item-game{
  position: relative;
  height: 150px;
  overflow: hidden;
  border: 1px blue solid;
  border-radius: 8px;
}

.item-game:hover{
  cursor: pointer;
}

.item-game .item-game_pic{
  display: block;
  height: 100%;
}

.item-game .item-game_pic img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item-game .item-game_info{
  display: none;
}

.item-game:hover .item-game_info{
  display: block;
}

.item-game:hover .item-game_pic{
  display: none;
}

.defaultImage{
  width: 100%;
  height: 200px;
  background-color: black;
}
.defaultImage h5{
  color:#f8f9fa;
  margin:0 auto;
}