@use 'src/common/styles/mixins';

.securityPasswordSuccess {
    width: 400px;
    height: 400px;
    background: linear-gradient(45deg, black 0%, #00B212 50%, black 100%);
    //filter: blur(100px);
    border-radius: var(--radius-40);
    @include mixins.flex($direction: column, $justifyContent: center, $alignItems: center);
    gap: 24px;
    padding: 10px;

    .title {
        @include mixins.title30();
        text-align: center;
    }
}