.buttonGroup {
    display: flex;

    .buttonDropdown {
        width: 100%;
    }

    .buttonProfile {
        min-width: 143px;
    }

    .navigationButton {
        border-radius: var(--radius-100);
    }

    .buttonContent {
        display: flex;

        .imgWithMargin {
            margin-left: 6px;
        }
    }

    .iconContainer {
       display: flex;
       align-items: center;

        .imgWithMargin {
            margin-left: 8px;
        }
    }
}

.buttonGroupGap {
    gap: 8px;
}
