@use '../styles/mixins';

.brightCarousel {
    display: flex;
    align-items: center;



    .scrollButtonLeft {
        margin-right: 8px;
    }

    .scrollButtonRight {
        margin-left: 8px;
    }
}

.scrollContainer {
    scrollbar-width: none; /* for Firefox */
}

.scrollContainer::-webkit-scrollbar {
    display: none; /* For Chrome, Safari & Edge */
}
