.errorMessage {
    margin-top: 8px;
    display: flex;
    gap: 4px;

    .errorFormText {
        color: var(--text-status-destructive);
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.28px;
    }
}