@use '../styles/mixins';

.button {
    
    border-radius: var(--radius-12);


    &.primary {
        background-color: var(--background-button-primary);

        &:hover {
            background-color: var(--background-button-primary-hover);
        }

        &:active {
            background-color: var(--background-button-primary-pressed-correct);
        }
    }

    &.glass {
        background-color: var(--background-button-glass);

       @include mixins.buttonGlassHoverActive();

        &:disabled {
            cursor: auto;
            color: var(--text-base-tetriary);

            &:hover {
                background-color: var(--background-button-glass);
            }
        }


    }

    &.accent {
      background-color: var(--background-surface-elevation-2);
      border: 2px solid var(--border-accent-brand-accent);
      color: var(--text-accent-brand-primary);

        &:hover {
            border: 2px solid var(--background-button-primary-hover);
            color: var(--background-button-primary-hover);
        }

        &:active {
            border: 2px solid var(--background-button-primary-pressed-correct);
            color: var(--background-button-primary-pressed-correct);
        }

        &:disabled {
            cursor: auto;
            color: var(--text-base-tetriary);

            &:hover {
                background-color: var(--background-button-glass);
            }
        }
        
    }


}


.width100 {
    width: 100%;
}

.mobile {
    border-radius: var(--radius-8);
    font-size: 12px;
}
