@import '../styles/_adaptive.scss';

@mixin accordionRootTermsUpdatedMobile {
    border-radius: var(--radius-12) !important;
    overflow: hidden;
}

.footerAccordions {
    display: flex;
    gap: 32px;
}

.footerAccordions .accordionRoot,
.accordionRoot {
    background-color: transparent;
    color: #fff;
    box-shadow: none;

    .accordionSummary {
        background-color: var(--background-surface-subtle-primary);
        border-radius: var(--radius-12);
        width: 233px;
        margin-bottom: 24px;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px
    }

    .accordionDetails {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 0 0 0 12px;

        .link {
            color: inherit;
            text-decoration: none;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.32px;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.accordionTermsSingle {

}

@media (min-width: 1440px) and (max-width: 1900px) {
    .footerAccordions {
        gap: 16px;


    }

    .footerAccordions .accordionRoot,
    .accordionRoot {
        .accordionSummary {
            width: 200px;
            margin-bottom: unset;
        }
    }
}

@media #{$mobile} {
    .footerAccordions {
        flex-direction: column;
        gap: 16px;
        margin-bottom: 24px;


    }

    .footerAccordions .accordionRoot,
    .accordionRoot {
        margin-top: 8px;

        .accordionSummary {
            width: 100%;
            margin-bottom: unset;
        }

        .accordionSummarySingleTerm {
            min-height: 40px;
            height: 40px;
        }

        .accordionDetails {
            margin-top: 8px;
        }
    }

    .accordionTermsSingle {
        margin-bottom: 0;
    }

    .accordionRootTermsUpdated, .accordionRootSingleTerm  {
        margin-top: 0 !important;
    }

    .accordionRootTermsUpdated {
        @include accordionRootTermsUpdatedMobile;
        border: 1px solid var(--border-base-default);
    }

    .accordionRootSingleTerm {
        @include accordionRootTermsUpdatedMobile;

    }

    .mobileAccordionRoot {
        background-color: var(--background-modal-primary) !important;
    }

    /*this style for opened mobile accordion*/
    .mobileAccordionRoot:global(.Mui-expanded) {
        background-color: var(--background-modal-tetriary) !important;
    }

    .accordionSummaryTermsUpdated {
        background-color: unset !important;
        :global(.MuiAccordionSummary-content) {
            margin: 0;
        }
    }

    .accordionDetailsTermsUpdated {
        overflow: hidden;
        margin-top: 0 !important;
        padding: 0 16px 16px 16px !important;
    }

    .accordionDetailsSingleTerm {
        padding: 0 16px 12px 16px !important;

    }

    .accordionSummaryTermsUpdated {
        border-radius: 0 !important;
    }


    .accordionDetailsTermsUpdated {
    }

    .accordionSummarySingleTerm {

        //height: 50px !important; // Новая высота
        height: 40px; // Тоже важно, иногда MUI задаёт min-height
    }
}

/*todo: reduce specificity and delete important*/