.addBonus{
    .bonusImgContainer {
        width: 100%;
        position: relative;
    
        .bonusImage {
            border-radius: var(--radius-24);
            width: 100%;
        }
    
        .countdown {
            
        }
    }

    .active, .countdown {
        position: absolute;
        border-radius: var(--radius-100);
    }

    --hint-offset: 24px;
    
    .active {
        color: var(--text-accent-green-primary);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        bottom: var(--hint-offset);
        left: var(--hint-offset);
        background: var(--background-chip-inverted);
        border: 1px solid var(--border-accent-green-accent);
        padding: 7px 8px;
    }

    .countdown {
        top: var(--hint-offset);
        left: var(--hint-offset);
        padding: 4px 8px;
        background: var(--background-surface-default);
        border: 1px solid var(--border-base-default);

        span {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.32px;
        }

        .divider {
            width: 1px;
            height: 24px;
            margin: 0 8px;
        }
    }

    .bonusInfoBlockContainer > div{
        padding: 0 12px;
    }
}

