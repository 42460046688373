.startTask {
   background: var(--background-tabs-normal-active);
   height: 40px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 0 12px;
   border-radius: var(--radius-12);

   .text {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.32px;
   }

   img {
      cursor: pointer;
   }
}
