.alertContainer {
    position: relative;
    background-color: transparent !important; /* Used !important to override Bootstrap's default alert styles */
    border: none !important;
    padding: 0 !important;
    color: var(--text-base-primary) !important;
    font-size: 16px !important;
}

.closeButton {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #000;
    opacity: 0.5;
    cursor: pointer;
}

.closeButton:hover {
    opacity: 0.75;
}


