@use '../../../../../../common/styles/mixins';

.promoInput {
    margin-top: 16px;
    
    display: flex;

    .input {
        @include mixins.input();
        border-radius: var(--radius-12) 0 0 var(--radius-12);
        padding: 9px 10px;
        background: var(--background-input-normal);
        width: 100%;

        &::placeholder {
            color: var(--text-base-tetriary);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.28px;
        }
    }

    .inputWrap{
        position: relative;
        width: 100%;
    }
    .checkedImg {
        position: absolute;
        right: 0;
        padding: 9px;
        display: none;
    }
    .button {
        height: 40px;
        border-radius: 0 var(--radius-12) var(--radius-12) 0;
        min-width: 72px;
        border: 1px solid var(--border-action-normal);
        border-left: none;
        background: var(--background-button-disabled);
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        color: var(--text-base-tetriary);
        flex-shrink: 0;
    }

    // .buttonActive {
    //     color: var(--text-base-primary);
    //     background-color: var(--background-button-primary);
    // }
}

.promoInputActive {
    & button{
        color: var(--text-base-primary) !important;
        background-color: var(--background-button-primary) !important;
    }
    
}

.promoInputSuccess {
    & button{
        color: var(--text-base-primary) !important;
        background-color: var(--background-button-primary) !important;
    }
    & input{
        border-color: var(--background-button-primary) !important;
    }

    & .checkedImg{
        display: inline  !important;
    }
    
}

.errorWrapper {
    @include mixins.flex($alignItems: center);
    gap: 4px;
    margin-top: 10px;

    .error {
       font-size: 14px;
       font-weight: 400;
       line-height: 22px;
       letter-spacing: -0.28px;
       color: var(--text-status-destructive);
    }
 }
