@import 'src/common/styles/_adaptive.scss';

.gameList {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

@media #{$mobile} {
    .gameList {
        column-gap: 5px;
        row-gap: var(--standard-small-padding);
    }
}