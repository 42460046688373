@use '../../../common/styles/mixins';

@mixin currencyButton() {
    background-color: var(--background-surface-elevation-2);
    border: 1px solid var(--border-base-default);

    @include mixins.buttonGlassHoverActive(); /*Done same styles hover, active as for glass button*/
}

@mixin currencyButtonSelected() {
    .currencyButtonSelected {
        border: 1px solid var(--border-accent-brand-accent);
    }
}


.button {
    width: 100%;
}

.buttonGroup {
    display: flex;
    gap: 8px;

    .navigationButton {
        border-radius: var(--radius-100);
    }

    .buttonContent {
        display: flex;

        .imgWithMargin {
            margin-left: 6px;
        }
    }
}
