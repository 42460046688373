@use 'src/common/styles/mixins';
@import 'src/common/styles/_adaptive.scss';

.depositBanner {
    height: 500px;
    background: linear-gradient(to top right, #B2006B, #6E00B2);
    border-radius: var(--radius-40);
    --left-part-width: 453px;
    --right-part-width: 420px;
    @include mixins.flexCenter();
    padding: var(--content-grid-padding);
    position: relative;

    .innerContainer {
        max-width: calc(var(--left-part-width) + var(--right-part-width));
        width: 100%;
        height: 100%;
        border-radius: var(--radius-40);

        display: grid;
        grid-template-columns: minmax(0, var(--left-part-width)) minmax(0, var(--right-part-width));
        overflow: hidden;

        .rightPart {
            background-color: var(--background-input-normal);
        }
    }

}

@media #{$mobile} {
    .depositBanner {
      padding: var(--content-grid-padding-mobile);
      border-radius: unset;
      height: unset;
      flex-direction: column; 

      .innerContainer {
          border-radius: var(--radius-24);
          grid-template-columns: 1fr;
          grid-template-rows: 1fr 1fr;
      }
    }
}

@media (min-width: 1440px) and (max-width: 1900px) {
    .depositBanner {
        --left-part-width: 380px;
        --right-part-width: 380px;
        justify-content: flex-start;
        padding: var(--content-grid-padding-mobile);
    }
}

@media (min-width: 1650px) and (max-width: 1900px) {
    .depositBanner {
        justify-content: center;
    }
}