@use '../../../common/styles/mixins';
@import 'src/common/styles/_adaptive.scss';

.sidebar {
    position: relative;
    z-index: 2;
    background-color: var(--background-surface-elevation-1);
    color: var(--text-base-secondary);
    width: 100%;
    padding: 24px 16px; // calculate to hide ChildrenMenu, maybe later will be better solution

    .sidebarItemsContainer {
        gap: var(--sidebar-items-gap);
    }

    .chevronContainer {
        display: flex;
        align-items: center;
        flex: 1;

        .title {
            flex: 1;
            @include mixins.sidebarItemFontStyles();
        }

        .chevronWrapper {
            display: flex;

            .chevron {
                margin-left: auto;
            }
        }

    }

    .wheelOfFortune {
        margin-bottom: calc(18px + var(--color-block-trophy-offset));
    }

    .freeBonuses {
    }

    .wheelOfFortune, .vipClub {
        margin-top: calc(18px + var(--color-block-trophy-offset));
    }

    .vipClub {
        margin-bottom: 40px;
    }

    .userIcon {
        @include mixins.userIcon24();
    }
}

@media #{$mobile} {
    .sidebar {
        padding: 8px 16px;
        top: var(--mobile-menu-top-shift);
        position: fixed;
        border-radius: 32px 32px 0 0;
        overflow-y: auto;
        max-height: calc(100vh - 79px); /*minus height of Tabbar*/

        /*hidden scroll*/
        -ms-overflow-style: none; /* For IE and Edge */
        scrollbar-width: none; /* For Firefox */

        &::-webkit-scrollbar {
            display: none; /* For Chrome, Safari and Opera */
        }

        .drag {
            border-radius: var(--radius-100);
            background: var(--background-surface-subtle-primary);
            margin: 0 auto;
            top: 0;
            width: 36px;
            height: 5px;
            cursor: pointer;
        }

        .freeBonuses {
            margin-bottom: var(--standart-block-padding);
        }

        .backContainer {
            display: flex;
            padding: 12px;
            gap: 10px;
            cursor: pointer;
        }

        .sidebarItemsContainer {
            gap: var(--sidebar-items-gap-mobile);
        }

        .wheelOfFortune {
            margin-bottom: var(--standart-block-padding);
        }

        .freeBonuses {
        }

        .wheelOfFortune, .vipClub {
            margin-top: var(--standart-block-padding);
        }

        .vipClub {
            padding-bottom: var(--standart-block-padding);
        }
    }
}

