@use '../../../common/styles/mixins';

.languageContainer {
    cursor: pointer;
    position: relative;

    .menu {
        right: 0;
        transform: translateY(8px);
        background-color: var(--background-surface-elevation-1);
        padding: var(--sidebar-children-menu-padding);
        border: 1px solid var(--border-base-default);
    }

    .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        color: var(--text-base-secondary);
        border-radius: var(--radius-12);
        min-width: 224px;
        padding: var(--sidebar-children-menu-padding);

        &:hover {
            background-color: var(--background-tabs-normal);
            color: var(--text-base-primary);
            cursor: pointer;
        }

        .flagTitle {
            display: flex;
            align-items: center;

            .flag {
                margin-right: 8px;
            }


        }

        .check {
            width: 24px;
            height: 24px;
        }
    }
}