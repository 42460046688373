@import 'src/common/styles/_adaptive.scss';

.mainLayout {
    position: relative; /* Provide a stacking context for the entire container */
    background-color: var(--background-surface-default);
    color: var(--text-base-primary);
    max-width: 1920px;

    h4 {
        font-size: 36px;
    }

    .gridContainer {
        display: grid;
        grid-template-columns: 280px 1fr;

        .sidebarGrid {
            position: relative; /* Ensure that the sidebar is managed in its own context */
            z-index: 2;
        }

        .contentGrid {
            display: flex;
            flex-direction: column;
            position: relative; /* Provide a stacking context for the content */
            z-index: 0;
            overflow: hidden;
            padding: var(--content-grid-padding);
        }
    }
}

@media #{$mobile} {
    .mainLayout {
        overflow-x: hidden;
        .gridContainer {
            grid-template-columns: 1fr;

            .contentGrid {
                padding: unset;
            }
        }
    }
}

