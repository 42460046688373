.tabsContainer {
    background-color: var(--background-surface-default);
    border-radius: 14px;
    padding: 2px; /* Tabs padding */
    display: inline-block;

    .tabs {
        height: 36px;
        min-height: 36px;
        display: flex;

        .tab {
            flex: 1;
            text-align: center;
            padding: 10px 14px;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            color: var(--text-base-secondary); /* Inactive color */
            border-radius: var(--radius-12);
            height: 36px;
            min-height: 36px;
            text-transform: none;
            text-wrap: nowrap;

            &:not(:last-child) {
                margin-right: 4px;
            }
        }

        .activeTab {
            background-color: var(--background-button-primary);
            color: var(--text-base-primary);
            border: 1px solid var(--border-base-default);
        }
    }


    .indicator {
        display: none; /* Remove the standard blue indicator */
    }
}