@use 'src/common/styles/mixins';
@import 'src/common/styles/_adaptive.scss';

.bannerRight {
    background-color: #000000;
    @include mixins.flex($direction: column, $alignItems: center, $justifyContent: space-between);
    padding: 78px 40px 40px 40px;
    
    .mainContent {
        width: 100%;
        
        .title {
            @include mixins.title24();
            margin-bottom: var(--standart-block-padding);
            text-align: center;
        }

        .selectInputContainer {
            @include mixins.flex($direction: row, $alignItems: center);
            margin-bottom: 15px;
            width: 100%;
            gap: 10px;

            .input {
                max-width: 100%;
                flex-grow: 1;
            }

            .select {
                width: 116px;
            }
        }
    }



    .button {
        
    }
}

@media #{$mobile} {
    .bannerRight {
        padding: 16px;

        .mainContent {
            .title {
                @include mixins.text14();
            }
        }
    }
}




