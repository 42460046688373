@use 'src/common/styles/mixins';

.accountsElement {
    width: 235px; // changed from 238px because of scrollbar
    background-color: var(--background-surface-elevation-1);
    padding: var(--standart-block-padding);
    border: 1px solid var(--border-base-default);
    border-radius: var(--radius-24);

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    cursor: pointer;

    .mainContent {
        margin-bottom: var(--standart-block-padding);
        
        .currencyContainer {
            display: flex;
            align-items: center;
            gap: 4px;
        }

        .currency_header {
           @include mixins.flex($justifyContent: space-between);
        }

        .moneyContainer {
           margin-top: var(--standart-block-padding);
            @include mixins.flex($direction: column);
            gap: 10px;

            .moneyBlock {
                .moneyType {
                    @include mixins.moneyType();
                    color: var(--text-base-secondary);
                }

                .amount {
                    color: var(--text-base-primary);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 18px; /* 112.5% */
                }
            }
        }

        .currency_code {
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
        }
    }
}

.accountsElementActive {
    border: 1px solid var(--border-accent-green-accent);
}
