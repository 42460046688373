.component {
    .otherBanks {
        color: var(--text-base-primary);
        font-size: 14px;
    }

    .paymentMethodImg {
        width: 86px;
        height: 40px;
    }
}