:root {
    /* Colours - Mode 1 */
    --colour-base-white: rgba(255, 255, 255, 1);
    --colour-base-black: rgba(0, 0, 0, 1);
    --colour-gray-1: rgba(246, 246, 246, 1);
    --colour-gray-2: rgba(233, 234, 236, 1);
    --colour-gray-3: rgba(222, 224, 227, 1);
    --colour-gray-4: rgba(200, 202, 208, 1);
    --colour-gray-5: rgba(186, 189, 197, 1);
    --colour-gray-6: rgba(157, 157, 163, 1);
    --colour-gray-7: rgba(122, 122, 128, 1);
    --colour-gray-8: rgba(108, 108, 113, 1);
    --colour-gray-9: rgba(86, 86, 89, 1);
    --colour-gray-10: rgba(64, 64, 67, 1);
    --colour-gray-11: rgba(49, 49, 51, 1);
    --colour-gray-12: rgba(38, 38, 38, 1);
    --colour-gray-13: rgba(31, 31, 31, 1);
    --colour-gray-14: rgba(20, 20, 20, 1);
    --colour-gray-1a: rgba(0, 0, 0, 0.05);
    --colour-gray-2a: rgba(0, 0, 0, 0.11);
    --colour-gray-3a: rgba(0, 0, 0, 0.23);
    --colour-gray-4a: rgba(0, 0, 0, 0.3);
    --colour-gray-5a: rgba(0, 0, 0, 0.4);
    --colour-gray-6a: rgba(0, 0, 0, 0.5);
    --colour-gray-7a: rgba(0, 0, 0, 0.6);
    --colour-gray-8a: rgba(0, 0, 0, 0.75);
    --colour-white-0a: rgba(255, 255, 255, 0.001);
    --colour-white-1a: rgba(255, 255, 255, 0.05);
    --colour-white-2a: rgba(255, 255, 255, 0.11);
    --colour-white-3a: rgba(255, 255, 255, 0.23);
    --colour-white-4a: rgba(255, 255, 255, 0.3);
    --colour-white-5a: rgba(255, 255, 255, 0.4);
    --colour-white-6a: rgba(255, 255, 255, 0.5);
    --colour-white-7a: rgba(255, 255, 255, 0.6);
    --colour-white-8a: rgba(255, 255, 255, 0.75);
    --colour-blue-1: rgba(3, 126, 229, 1);
    --colour-blue-2: rgba(2, 84, 153, 1);
    --colour-blue-1a: rgba(19, 109, 215, 0.2);
    --colour-blue-2a: rgba(2, 84, 153, 0.56);
    --colour-green-1: rgba(38, 178, 12, 1);
    --colour-green-2: rgba(19, 125, 74, 1);
    --colour-green-1a: rgba(38, 178, 12, 0.2);
    --colour-green-2a: rgba(19, 125, 74, 0.56);
    --colour-red-1: rgba(241, 80, 50, 1);
    --colour-red-2: rgba(197, 45, 16, 1);
    --colour-red-1a: rgba(241, 80, 50, 0.2);
    --colour-red-2a: rgba(241, 80, 50, 0.56);
    --colour-toxic-1: rgba(89, 255, 106, 1);
    --colour-toxic-2: rgba(14, 224, 35, 1);
    --colour-toxic-3: rgba(0, 178, 18, 1);
    --colour-toxic-4: rgba(0, 129, 13, 1);
    --colour-toxic-1a: rgba(0, 178, 18, 0.2);
    --colour-toxic-2a: rgba(0, 178, 18, 0.56);
    --colour-orange-1: rgba(255, 146, 19, 1);
    --colour-orange-2: rgba(224, 121, 0, 1);
    --colour-orange-1a: rgba(255, 146, 19, 0.2);
    --colour-orange-2a: rgba(255, 146, 19, 0.56);
}
