@use 'src/common/styles/mixins';
@import 'src/common/styles/_adaptive.scss';

.footer {
    padding: 40px 0;
    margin-top: auto;

    .footerContainer {
        display: flex;
        justify-content: space-between;
        margin-bottom: 48px;

        .data {
            .logoContainer {
                margin-bottom: 22px;
            }

            .text {
                max-width: 352px;
                margin-bottom: 0;
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: -0.28px;
            }
        }
    }

    .bottomContainer {
        margin-top: 28px;
        display: flex;
        justify-content: space-between;

        .bottomText {
            font-size: 16px;
            font-style: normal;
            line-height: 24px;
            letter-spacing: -0.32px;
        }

        .socialIcons {
            display: flex;
            gap: 12px;

            img {
                cursor: pointer;
            }
        }
    }
}

@media (min-width: 1440px) and (max-width: 1900px) {
    .footer {
        .footerContainer {
            .data {
                .text {
                    max-width: 208px;
                }
            }
        }
    }
}

@media #{$mobile} {
    .footer {
        padding: 0;
        
        .footerContainer {
            flex-direction: column;
            justify-content: unset;
            margin: 16px 16px 0 16px;

            .data {
                .text {
                    padding-bottom: var(--standart-block-padding);
                    max-width: unset;
                }

                .logoContainer {
                    margin-bottom: 16px;
                }
            }


        }

        .bottomContainer {
            flex-direction: column;
            justify-content: unset;
            gap: var(--standard-small-padding);
            margin: var(--standart-block-padding)  var(--standard-small-padding);
        }
    }
}