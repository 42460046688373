@use '../styles/mixins';
@import 'src/common/styles/_adaptive.scss';

.scrollButton {
    --buttons-size: 40px;

    min-width: var(--buttons-size);
    width: var(--buttons-size);
    height: var(--buttons-size);
    cursor: pointer;
    border-radius: (var(--radius-100));
    background: var(--background-surface-subtle-primary);
    @include mixins.flexCenter();

    pointer-events: auto;

    &:hover {
        background: var(--background-button-glass-hover);
    }

    &:active {
        background: var(--background-button-glass-pressed-correct);
    }

}

@media #{$mobile} {
    .scrollButton {
        --buttons-size: 32px;

        img {
            width: 16px;
            height: 16px;
        }
    }
}