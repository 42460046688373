@use '../../../../common/styles/mixins';
@import 'src/common/styles/_adaptive.scss';

.questionBlock {
    min-height: var(--color-qustions-block-height);
    width: 100%;
    padding: 12px 16px;
    background-color: var(--background-surface-elevation-2);
    border-radius: var(--radius-16);
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;

    .textContainer {
        .title {
            @include mixins.ColorQuestionsBlockTitle();
        }

        .subtitle {
            @include mixins.ColorQuestionsBlockSubtitle();
        }
    }

    .imageContainer {
        display: flex;

        .imageElement {
            width: 40px;
            height: 40px;
            border-radius: var(--radius-100);
            background-color: rgba(255, 255, 255, 0.25);
            @include mixins.flexCenter();
            cursor: pointer;

            &:not(:last-child) {
                margin-right: 8px;
            }
        }
    }
}

@media #{$mobile} {
    .questionBlock {
        margin-top: var(--standart-block-padding);
    }
}