.buttonStyledProfile {
    height: 40px;
    border-radius: 0 !important;
    background-color: unset;
    padding: 12px;
    color: var(--text-base-secondary);
    position: relative;

    &.selected {
      color: var(--text-base-primary);

      /*this pseudo element for border above*/
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: var(--border-action-brand-1);
        bottom: -2px;
      }
    }
}