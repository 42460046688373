@use 'src/common/styles/mixins';

.gridContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 32px;
    row-gap: 24px;

    .buttonMessages {
        @include mixins.flex($direction: column);

        .button {
            margin-top: auto;
            width: 100%;
        }

        .alert {
            width: 100%;
            @include mixins.flex();
            margin: 0;
        }
    }




}

.input {
    width: 100%;
}

.label {
    color: var(--text-base-primary);
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 8px;
}

.personal {
    background-color: var(--background-surface-elevation-1);
}