.experienceLoyaltyElement {
    .img {
        width: 40px;
        height: 40px;
    }

    .points, .lvl {
        display: flex;
        justify-content: space-between;
    }

    .lvl {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -0.48px;
        margin-top: 8px;
    }

    .points {
        color: var(--text-base-secondary);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.32px;
        margin-top: 4px;
    }
}