@use '../styles/mixins';
@import '../styles/_adaptive.scss';

.link {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
}

.name {
    display: flex;
    align-items: center;
    padding: 0;
}

.itemsContainer {
    @include mixins.SidebarElementsContainer();

    .item {
        display: flex;
        align-items: center;
        height: var(--sidebar-item-height);
        text-wrap: nowrap;
        border-radius: var(--radius-12);
        padding: 12px;

        &:hover {
            @include mixins.itemHover();
        }
    }

    .trophy {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }

}


.group {
    @include mixins.blockBottomMargin();
}

.groupMobile {
    margin: 40px 16px;
}

.groupMobileMobileMargin24 {
    @include mixins.blockBottomMarginMobile24();
}

.groupMobileMobileMarginBottom16 {
    margin-bottom: 16px;
}

.checkboxHint {
    color: var(--text-base-primary);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.28px;
}

.userIcon {
    --icon-size: 40px;

    height: var(--icon-size);
    min-width: var(--icon-size);
    max-width: var(--icon-size);
}

.userIconMobile {
    --icon-size: 24px;
}

.accountElement {
    padding: var(--standart-block-padding);
    background-color: var(--background-surface-elevation-2);
    border-radius: var(--radius-24);
    display: flex;
    flex-direction: column;

    .header {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -0.48px;
    }
}

.button {
    text-transform: none;
    font-size: 14px;
    border: none;
    font-weight: 600;
    font-family: var(--font-family), sans-serif;
    cursor: pointer;
    box-shadow: none;
    transition: background-color 0.2s ease, transform 0.1s ease;
    text-decoration: none;
    @include mixins.flexCenter();
    color: var(--text-base-primary);
    text-wrap: nowrap;

}

.pageContainer {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.profileRoundedTab {
    border-radius: 0 0 var(--radius-40) var(--radius-40);
    border-top: 2px solid var(--border-base-tetriary);
    background-color: var(--background-surface-elevation-1);
    padding: 24px;
}

.profileRoundedTabNoPadding {
    @extend .profileRoundedTab;
    padding: 0;
    margin-bottom: 40px;
    overflow: hidden;
}

.inputSelectLabel {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
}

// table statuses colors
.statusApproved {
    color: var(--text-status-success);
}

.statusRejected {
    color: var(--text-status-destructive);
}

.statusPending {
    color: var(--text-status-pending);
}

// For Deposit & AddWallet
.currencyCode {
    display: flex;
    gap: 4px;
    justify-content: center;
    align-items: center;
}
.currencyButton {
    @include mixins.currencyButton();
    color: var(--text-base-primary);
    height: 48px;
    border-radius: var(--radius-100);
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    min-width: 85px; /* Minimal button width */
}


@media #{$mobile} {
    .itemsContainer {

        .item {
            padding: 8px 12px;
        }
    }

    .pageContainer {
        gap: 24px;
    }
}