@use 'src/common/styles/mixins';

.limitsElement {
    background-color: var(--background-surface-elevation-2);
    width: 366px;
    height: 400px;
    padding: var(--standart-block-padding);
    border-radius: var(--radius-24);
    @include mixins.flex($direction: column, $justifyContent: space-between);
    position: relative;

    .imagesBlock{
        display: flex;
        justify-content: space-between;
        
        & div{
            flex: 1;
        }

        .menuBox{
            width: 100%;
            position: relative;

            .menuButtonBox {
                width: 100%;
                display: flex;
                justify-content: end;
                .menuButton {
                    background: none;
                    border: none;
                    font-size: 24px;
                    cursor: pointer;
                    padding: 5px;
                }
            }

            .menuDropdown{
                position: absolute;
                left: 10px;
                top: 100%;
                z-index: 1050;
                background-color: rgb(20, 20, 20);
                border: 1px solid rgb(53, 53, 53);
                width: 100%;
                border-radius: 10px;
                padding: 10px;
        
                .menuDropdownItem{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 5px 0;
                    border-bottom: 1px solid rgb(53, 53, 53);
                    
                    &:first-child{
                        margin-top: -5px;
                    }

                    &:last-child{
                        border-bottom: none;
                        margin-bottom: -5px
                    }
                    & p{
                        font-size: 13px;
                        margin-bottom: 0;
                    }
                   
                }
            }

        }
        
        
    }

    .button {
        width: 100%;
    }

    .title {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px; 
        letter-spacing: -0.48px;
        margin-top: 16px;
    }

    .description {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.32px
    }

    .data{
        margin-top: 20px;
        .dataRaw{
            display: flex;
            justify-content: space-between;

            p{
                font-size: 14px;
            }
        }
    }
}