.playerContainer {
    display: flex;
    gap: 10px;
    align-items: center;

    .playerData {
        .name, .player {
            color: var(--text-base-primary);
        }

        .name {
            font-size: 20px;
            font-weight: 700;
            line-height: 28px;
            letter-spacing: -0.4px;
        }

        .playerContainer {
            display: flex;
            gap: 8px;
            max-width: 100%;

            .player {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: -0.32px;
                text-wrap: nowrap;
                max-width: 100%;
                white-space: nowrap;
            }

            .playerID {
                max-width: 159px;
                overflow: hidden;
                text-wrap: nowrap;
                text-overflow: ellipsis;
                color: var(--text-base-secondary);
            }


        }
    }
}