@import 'src/common/styles/_adaptive.scss';

.siteLogo {
    width: 190px;
    height: 26px;
}

.siteLogo132x20{
    width: 132px;
    height: 20px;
}

@media #{$mobile} {
    .siteLogo.adaptive {
        width: 95px;
        height: 13px;
    }
}

