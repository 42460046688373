@use '../../../common/styles/mixins';
@import 'src/common/styles/_adaptive.scss';

.component {
    height: 356px;
    font-size: 72px;
    font-weight: 700;
    line-height: 80px;
    letter-spacing: -1.44px;
    @include mixins.flexCenter();
    background: linear-gradient(45deg, #F00 0%, #FFD400 100%);
    border-radius: var(--radius-32);
    position: relative;
    overflow: hidden;
    cursor: pointer;

    .money_2, .money_2_2 {
        position: absolute;
    }

    .money_2 {
        left: 0;
        bottom: 0;
    }

    .money_2_2 {
        right: 0;
        bottom: 0;
    }
}

@media #{$mobile} {
    .component {
        margin: 40px 16px;
        height: 88px;
        border-radius: var(--radius-24);
        @include mixins.text20();

        .money_2 {
           width: 61px;
           height: 68px;
        }

        .money_2_2 {
            width: 68px;
            height: 68px;
        }
    }
}