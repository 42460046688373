.balance {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .balances {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .button {
        margin-top: 20px;
    }
}