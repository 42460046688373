@use '../styles/mixins';
@import 'src/common/styles/_adaptive.scss';

.titleContainer {
    --title-size: 44px;
    --winners-icon-size: 32px;

    display: flex;
    align-items: center;
    height: var(--title-size);

    .iconContainer {
        width: var(--title-size);
        height: var(--title-size);
        @include mixins.flexCenter();
        background-color: var(--background-surface-subtle-secondary);
        border-radius: var(--radius-12);
        padding: 6px;

        .winners_icon {
            width: var(--winners-icon-size);
            height: var(--winners-icon-size);
        }
    }

    h5 {
        margin-left: 12px;
        @include mixins.h5Group();
        margin-bottom: 0;
    }
}

.marginBottom {
    margin-bottom: 40px;
}

@media #{$mobile} {
    .titleContainer {
        --title-size: 24px;
        --winners-icon-size: 16px;
        margin-left: var(--standard-small-padding);

        .iconContainer {
            border-radius: 6px;
        }

        h5 {
            margin-left: 8px;
        }
    }

    .titleContainerClickable {
        h5 {
            cursor: pointer;
        }
    }
}
