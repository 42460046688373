@use 'src/common/styles/mixins';

.fullScreenModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    background: var(--background-surface-default);
    z-index: 1000;


    //@include mixins.flex($justifyContent: center);
}