@import 'src/common/styles/_adaptive.scss';

.depositMethods {
    display: flex;
    gap: 19px;
}

@media #{$mobile} {
    .depositMethods {
        gap: 8px;
        justify-content: center;
    }
}
