@use '../../common/styles/mixins';
@import 'src/common/styles/_adaptive.scss';

@mixin opacity-transition {
    transition: opacity 0.3s ease;
}

.gameCard {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;

    .gameImage {
        width: 100%;
        height: 100%;
        @include opacity-transition;
        border-radius: var(--radius-24);
    }

    .hoverContainer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        opacity: 0;
        @include opacity-transition;
        padding: 16px;
        backdrop-filter: blur(12px);
        border-radius: var(--radius-24);

        .gameTitleContainer {
            display: grid;
            grid-template-columns: 1fr auto;
            align-items: center;
            width: 100%;
            font-weight: 700;
            line-height: 28px;
            letter-spacing: -0.4px;

            .gameName {
                @include opacity-transition;
                @include mixins.text20();
                white-space: normal;
            }

            .favoriteIcon {
                cursor: pointer;
                align-self: start;
            }
        }

        .buttonGroup,
        .providerName {
            @include opacity-transition;
        }

        .providerName {
            @include mixins.text16();
            letter-spacing: -0.32px;
            margin: 0;
        }

        .buttonGroup {
            display: flex;
            flex-direction: column;
            gap: 10px;

            width: 100%;
        }
    }

    &:hover .hoverContainer {
        opacity: 1;
    }
}

.variantGameWidget {
    width: 240px;
    height: 360px;
}

.variantGameList {
    width: 232px;
    height: 350px;
}

@media (min-width: 1440px) and (max-width: 1900px) {
    .gameCard {
        .hoverContainer {
            .gameTitleContainer {
                .gameName {
                    @include mixins.text16_weight600();
                    line-height: 18px;
                    letter-spacing: unset;
                }
            }

            .providerName {
                @include mixins.text14();
            }
        }
    }

    .variantGameWidget {
        width: 166px;
        height: 250px;
    }
}

@media #{$mobile} {
    .gameCard {
        .gameImage {
            border-radius: var(--radius-12);
        }

        .hoverContainer {
            border-radius: var(--radius-12);
            padding: 6px;

            .gameTitleContainer {
                .gameName {
                    @include mixins.text10();
                }

                .favoriteIcon {
                    width: 14px;
                    height: 14px;
                }
            }

            .providerName {
                @include mixins.text10();
            }
        }
    }


    .variantGameWidget {
        width: 111px;
        height: 180px;
    }

    .variantGameList {
        width: 111px;
        height: 180px;
    }
}