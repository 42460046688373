@use '../../../../../common/styles/mixins';
@import 'src/common/styles/_adaptive.scss';

.depositRightSidebarElement {
    background-color: var(--background-surface-subtle-primary);
    padding: 8px 12px;
    width: 100%;
    border-radius: var(--radius-16) 0 0 var(--radius-16);
    @include mixins.flex();
    gap: 8px;

    .image {
        width: 56px;
        height: 56px;
    }

    .rightPart {
        padding-right: 40px;

        .amount {
            @include mixins.title24();
        }

        .text {
            @include mixins.text16_weight600();
            color: var(--text-base-secondary);
        }
    }
}

@media (min-width: 1440px) and (max-width: 1900px) {
    .depositRightSidebarElement {
        max-width: 240px;

        .rightPart {
            padding-right: 0;
        }
    }
}

@media #{$mobile} {
    .depositRightSidebarElement {
        border-radius: var(--radius-12);
        flex-direction: column;
        height: 100%;
        gap: 4px;
        justify-content: center;

        .image {
            width: 32px;
            height: 32px;
        }

        .rightPart {
            width: 100%;
            padding-right: unset;
            
            .amount {
               @include mixins.text16_mobile_bonus();
            }

            .text {
                @include mixins.text14();
                width: 100%;
            }

            .star {
                width: 14px;
                height: 14px;
            }
        }
    }
}