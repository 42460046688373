@use 'src/common/styles/mixins';
@import 'src/common/styles/_adaptive.scss';

.depositRightSidebar {
   position: absolute;
   right: 0;
   @include mixins.flex($direction: column);
   gap: 16px;
}

@media #{$mobile} {
   .depositRightSidebar {
      margin-top: 16px;
      position: static;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      gap: 10px;
   }
}