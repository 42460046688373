@use 'src/common/styles/mixins';

.popupBackground {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    // background-color: rgba(223,232,233,0.3);
    // background: linear-gradient(180deg, rgba(50, 53, 53, 0.3), rgba(223,232,233,0.3), rgba(50, 53, 53, 0.3));
    border-radius: var(--radius-40);
    background-size: cover;
    background-position: center;
    z-index: 1001;
}

.popupContainer {
    z-index: 1002;
    width: 100%;
    // border-radius: 16px;
    // padding: 24px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    text-align: center;
    color: var(--text-base-primary);
    display: flex;
    flex-direction: column;
    // gap: 16px;
    align-self: stretch;
    justify-content: space-between;
    // min-height: 350px;

    .popupContainerTop {
        display: flex;
        flex-direction: column;
        width: 100%;

        .title {
            // font-size: 28px;
            // margin-bottom: 18px;
            // font-weight: bold;
            // color: white;
            // width: 100%;
            // text-align: left;

            font-family: "Inter", sans-serif;
            line-height: 1.2;
            letter-spacing: -0.02em;
            padding-bottom: 8px;

            &.white {
                color: #F6F6F6;
            }

            &.grey {
                color: #7A7A80;
            }

            &.black {
                color: #000;
            }

            &.red {
                color: #F15032;
            }

            &.green {
                color: #00B212;
            }

            &.blue {
                color: #037EE5;
            }

            &.yellow {
                color: #FCC419;
            }

            &.orange {
                color: #FF9213;
            }
        }

        .description {
            // font-size: 16px;
            // color: #ddd;
            // width: 100%;
            // text-align: left;

            font-family: "Inter", sans-serif;
            line-height: 1.2;
            letter-spacing: -0.02em;

            &.white {
                color: #F6F6F6;
            }

            &.grey {
                color: #7A7A80;
            }

            &.black {
                color: #000;
            }

            &.red {
                color: #F15032;
            }

            &.green {
                color: #00B212;
            }

            &.blue {
                color: #037EE5;
            }

            &.yellow {
                color: #FCC419;
            }

            &.orange {
                color: #FF9213;
            }
        }
    }


    .popupContainerBottom {
        display: flex;
        flex-direction: column;
        width: 100%;

        .timer {
            display: flex;
            flex-direction: column;
            align-items: center;
            // gap: 6px;
            width: 100%;
            margin-bottom: 14px;

            .timerText {
                font-size: 16px;
                line-height: 16px;
                color: #fff;
                margin-bottom: 14px;
                padding-bottom: 0;
            }

            .progress {
                width: 80%;
                height: 12px;
                background: rgba(255, 255, 255, 0.2);
                border-radius: 6px;
                overflow: hidden;
                position: relative;

                .progressBar {
                    height: 100%;
                    background: #fff;
                    transition: width 1s linear;
                    /* Плавное изменение ширины */
                }

                // &::after {
                //     content: "";
                //     position: absolute;
                //     height: 100%;
                //     width: 100%;
                //     background: #fff;
                //     animation: progressAnimation 8s linear forwards;
                // }
            }
        }

        .button {
            width: 100%;
            padding: 10px;
            font-size: 16px;
            border-radius: 24px;
            cursor: pointer;
            transition: 0.3s;
            // line-height: normal !important;
            line-height: 1;

            color: #fff;
            border: none;
            min-height: 40px;
            font-family: "Inter", sans-serif;
            font-weight: 600;

            &.white {
                background-color: #F6F6F6;
                color: #000;

                &:hover {
                    background-color: #DEE0E3;
                }
            }

            &.grey {
                background-color: #7A7A80;

                &:hover {
                    background-color: #9D9DA3;
                }
            }

            &.black {
                background-color: #000;
                // &:hover {
                //     background-color: #9D9DA3;
                // }
            }

            &.red {
                background-color: #F15032;

                &:hover {
                    background-color: #FF684C;
                }
            }

            &.green {
                background-color: #00B212;

                &:hover {
                    background-color: #0ACF1E;
                }
            }

            &.blue {
                background-color: #037EE5;

                &:hover {
                    background-color: #118CE3;
                }
            }

            &.yellow {
                background-color: #FCC419;
                color: #000;
                // &:hover {
                //     background-color: #9D9DA3;
                // }
            }

            &.orange {
                background-color: #FF9213;
                // &:hover {
                //     background-color: #9D9DA3;
                // }
            }
        }
    }

}

// @keyframes progressAnimation {
//     from {
//         width: 100%;
//     }
//     to {
//         width: 0%;
//     }
// }