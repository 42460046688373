@use '../../../../../../common/styles/mixins';

.balanceElement {


    .variant {
       @include mixins.hintVariant();
    }

    .amount {
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: -0.4px;
        display: flex;
        align-items: center;
        gap: 4px;
    }
}