@use '../../../../../common/styles/mixins';

.verifyProfile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .topContainer {
        img {
            margin-bottom: 16px;
        }

        .text {
            margin-top: 8px;
            @include mixins.profileText();
        }
    }

    .button {
        margin-top: 16px;
    }
}