.navigationButtonsContainer {
    background-color: var(--background-surface-elevation-2);
    padding: 4px;
    border-radius: var(--radius-32);
}

.navigationButtonsContainerProfileMain {

}

.profileVariant, .navigationButtonsContainerProfileMain  {
    width: fit-content;
}



