@use '../../../common/styles/mixins';
@import 'src/common/styles/_adaptive.scss';

.widgetContainer {
    .groupContainer {
        @include mixins.blockBottomMargin();

        .titleContainer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 28px;
            //border: 1px solid red;

            .titleAndControls {
                display: flex;
                align-items: center;

                h5 {
                    @include mixins.h5Group();
                }

                .sliderControls {
                    margin-left: 32px;
                    display: flex;
                    gap: 10px;
                }
            }
        }

        .gamesContainer {

        }
    }
}

.carousel {
    @include mixins.carouselSettings();
}

@media #{$mobile} {
    .widgetContainer {
        .groupContainer {
            .titleContainer {
                margin-bottom: var(--standard-small-padding);

                .titleAndControls {
                    justify-content: space-between;
                    width: 100%;
                    padding-right: 16px;
                }
            }
        }
    }

    .carousel {
        gap: 5px;
        padding: 0 16px;
    }
}

