.component {
  display: grid;
  grid-template-columns: 1fr; /* В дефолтной ориентации текст и изображение располагаются друг под другом */
  grid-template-rows: auto auto; /* Текст занимает верхнюю часть, а изображение — нижнюю */
  height: 100vh;
  overflow: hidden;
  gap: 20px; /* Отступ между элементами */

  /* Общие стили для текста */
  .topTitle, .text, .textSmall {
    color: var(--text-base-primary);
    text-align: center; /* Центрируем текст по умолчанию */
  }

  .topTitle {
    font-size: 48px;
    font-weight: 700;
    line-height: 72px;
    letter-spacing: -1.28px;
    padding-top: 20px;
  }

  .text {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.32px;
    margin-top: 20px;
  }

  .textSmall {
    font-size: 14px;
    font-weight: normal;
    line-height: 24px;
    letter-spacing: -0.32px;
    opacity: 0.5;
    margin-top: 10px;
    font-family: Arial, Helvetica, sans-serif;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px; /* Отступы для текстового блока */
  }

  /* Стили для изображения */
  .imgWrap {
    display: flex;
    align-items: center;
    justify-content: center;

    .mainImg {
      max-width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  /* Адаптивность */
  @media (max-width: 920px) and (orientation: landscape) {
    grid-template-columns: 1fr 1fr; /* Две колонки: текст слева, изображение справа */
    grid-template-rows: 1fr; /* Высота растягивается на весь экран */
    align-items: center;

    .textContainer {
      align-items: flex-start; /* Выровнять текст по левому краю */
      padding: 0 40px;
    }

    .topTitle, .text, .textSmall {
      text-align: left; /* В landscape-режиме текст слева */
    }

    .imgWrap {
      justify-content: flex-end; /* Изображение выравнивается по правому краю */
      padding-right: 20px;
    }
  }

  @media (max-width: 768px) {
    .topTitle {
      font-size: 36px;
      line-height: 50px;
    }

    .text {
      font-size: 16px;
    }

    .imgWrap {
      .mainImg {
        max-width: 80%;
      }
    }
  }

  @media (max-width: 480px) {
    .topTitle {
      font-size: 28px;
      line-height: 40px;
    }

    .text {
      font-size: 14px;
    }

    .imgWrap {
      .mainImg {
        max-width: 100%;
      }
    }
  }
}
