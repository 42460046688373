@use 'src/common/styles/mixins';

.documentElement {
    background-color: var(--background-surface-elevation-2);
    border-radius: var(--radius-16);
    padding: var(--standard-small-padding);
    width: 210px; /*changed from Figma 213.2px to include 5 columns on 1080p*/
    height: 308px;
    @include mixins.flex($direction: column, $justifyContent: space-between);
    
    .document {
        max-width: 100%;

        .status {
            text-transform: lowercase;
            @include mixins.text14();

            &::first-letter {
                text-transform: capitalize;
            }
        }

        .doctype {
            @include mixins.text16();
            margin-bottom: 0;
        }

        .filename {
            @include mixins.text14();
            margin-top: var(--standart-block-padding);
            max-width: 100%;
            word-wrap: break-word;
            overflow-wrap: break-word;
            white-space: normal;
        }
    }

    .dateTimeContainer {
        @include mixins.flex($justifyContent: space-between);
        width: 100%;

        .dateTime {
            @include mixins.text14();
            color: var(--text-base-secondary);
        }
    }
}
