@use 'src/common/styles/mixins';
@import 'src/common/styles/_adaptive.scss';

.login {
    color: var(--text-base-secondary);

    --right-part: 496px;

    height: 436px;
    width: fit-content;

    .gridContainer {
        display: grid;
        grid-template-columns: var(--login-register-left-part) var(--right-part);
        height: 100%;

        .leftContent {
            height: 100%;
            background-size: cover; // Фон заполняет блок
            background-position: top;  // Прижимаем картинку к верху
            background-repeat: no-repeat; // Отключаем повтор
        }

        .rightContent {
        }
    }

    .formInputs {
        display: flex;
        flex-direction: column;
        gap: var(--auth-modal-small-margin);
        margin: var(--auth-modal-big-margin) 0;
    }

    .forgotPasswordContainer {
        margin-top: var(--auth-modal-small-margin);
        cursor: auto;
        display: flex;
        justify-content: center;

        .forgetText {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.28px;
            cursor: pointer;
        }
    }
}

@media #{$mobile} {
    .login {
        padding: 16px;
        width: 100%;
        height: unset;
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - var(--mobile-status-bar-height));
        overflow: hidden;

        .gridContainer {
            //@include mixins.flex($direction: column);
            grid-template-columns: auto;
            grid-template-rows: auto auto;

            .leftContent {
                order: 2;

                width: 100%;
                max-height: 120px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                background-color: var(--background-button-glass);
                background-image: var(--background);

                margin-bottom: auto;
            }

            .rightContent {
                order: 1;
            }

        }

        .loginFormComponents {
            margin-top: auto;
        }
    }
}