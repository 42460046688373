@use '../../../common/styles/mixins';

.notification {
    cursor: pointer;
    width: 40px;
    height: 40px;
    background-color: var(--background-button-glass);
    border-radius: var(--radius-12);
    @include mixins.flexCenter();

    img {
        width: 12px;
        height: 12px;
    }
}