@use 'src/common/styles/mixins';
@import 'src/common/styles/_adaptive.scss';

.mobileStatus {
    height: var(--mobile-status-bar-height);
    width: 100%;
    background: var(--background-surface-elevation-3);
    @include mixins.flex( $alignItems: center, $justifyContent: space-between);
    padding: 0 10px;

    img {
        cursor: pointer;
    }

    .back, .header {
        color: var(--text-base-primary);
    }

    .back {
      @include mixins.text16();
    }

    .backContainer {
       @include mixins.flex( $alignItems: center);
        cursor: pointer;
    }

    .header {
        @include mixins.text16_weight600();
    }


}