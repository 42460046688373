@use '../../../common/styles/mixins';
@import 'src/common/styles/_adaptive.scss';

.gameGroupContainer {
    margin-bottom: 80px;

    .categoryName {
        text-transform: capitalize;
    }

    .gameGroup {
       @include mixins.gameGroup();

        .nameContainer {
            display: flex;
            padding: var(--game-group-padding) 0;
        }


        .categoryName {
            @include mixins.gameCategoryName();
        }

        .paginationContainer {
            margin-top: 40px;
        }
    }
}

@media #{$mobile} {
    .gameGroupContainer {
        margin-bottom: 40px;

        .gameGroup {
            margin-top: 0;
            padding: var(--mobile-element-padding);
            border-radius: 0;
            background-color: var(--background-surface-default);

            .nameContainer {
                padding: 0 0 16px 0;
            }

            .categoryName {
                margin-left: 0;
                @include mixins.text18();
            }
        }
    }
}

