@use 'src/common/styles/mixins';

.currencyComingSoon {
    width: 100%;
    color: var(--text-base-primary);

    .soon {
        @include mixins.flexCenter();
        height: 358px; /*empirical height*/
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -0.48px;
    }
}