@use 'src/common/styles/mixins';
@import 'src/common/styles/_adaptive.scss';

.tabbar {
    background: var(--background-surface-elevation-1);
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 1000;
    
    .tabs {
       height: 50px;
       @include mixins.flex( $justifyContent: space-around);
    }

    .homeIndicatorContainer {
        height: 29px;
        @include mixins.flex( $justifyContent: center, $alignItems: flex-end);
        padding-bottom: 8px;
        

        .homeIndicator {
            width: 139px;
            height: 5px;
            background: #fff;
            border-radius: 100px;
            cursor: pointer;
        }
    }
}

.tabbarNoBackground {
    background: none;
}