@use 'src/common/styles/mixins';


.textInput {
    position: relative;

    --currency-width: 28px;
    --currency-margin: 10px;

    label {
        margin-bottom: 0;
    }

    .title {
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        color: var(--text-base-primary);
    }

    .inputWrap {
        

        .input {
            @include mixins.input();
            background: var(--background-input-normal);
            color: var(--text-base-primary);
            padding: 0 calc(var(--currency-width) + var(--currency-margin)) 0 var(--currency-margin);
            margin: 8px 0;
            position: relative;

            &:focus {
                outline: none;
                background: var(--background-input-normal);
                border: 1px solid var(--border-action-normal);
                color: var(--text-base-primary);
            }

            &.error {
                background: var(--background-input-error);
                border: 1px solid var(--border-action-error);
                color: var(--text-base-error);
            }

            &::placeholder {
                color: var(--text-base-secondary);
            }
        }

        .extraname {}
    }

    .currency {
        position: absolute;
        top: 50%;
        right: var(--currency-margin);
        transform: translateY(-50%);
        font-size: 14px;
        color: var(--text-base-primary);

        font-weight: 600;
        line-height: 16px;
        width: var(--currency-width);
        pointer-events: none;
    }

    .hint {
        @include mixins.hintVariant();
    }



}