@use 'src/common/styles/mixins';

.accountFavouriteGames {
    .headerContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .games {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 16px;
        height: 100%;

        .noGamesContainer {
            display: flex;
            flex-direction: column;;
            height: 100%;
            justify-content: space-between;

            .noGamesText {
                color: var(--text-base-secondary);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: -0.32px;
            }
        }


    }
}