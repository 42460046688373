.accounts {
    background-color: var(--background-surface-elevation-2);

    .accountsContainer {
        display: flex;
        gap: 16px;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    .headerContainer {
        display: flex;
        gap: 40px;
        margin-bottom: 24px;

        .button {
            border-radius: var(--radius-12);
        }
    }
    
    .button {
        width: 38px;
        height: 38px;
        min-width: 38px;
    }

    .linearProgress {
        margin-top: 5px;
    }
}

