.profile {

    .headerContainer {
        display: flex;
        justify-content: space-between;

        .leftContainer {
            .accountPlayer {
                display: flex;
                gap: 31px;
            }

            .navigationContainer {
                margin-top: 46px;
            }
        }
    }

    .mainContainer {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
        gap: 16px;
    }
}