@use '../../../common/styles/mixins';
@import 'src/common/styles/_adaptive.scss';

@mixin player16_title() {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.32px;
}

@mixin player12_title() {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;
}

.winners {
    --button-width: 133px;
    --element-width: 372px;

    margin-top: var(--margin-between-block); // added because not styled of Providers

    .title {
        margin-bottom: 40px;
    }

    .element {
        --element-gap: 16px;
        --game-image-height: 128px;

        display: flex;
        align-items: center;
        padding: var(--element-gap);
        min-width: var(--element-width);
        max-width: calc(var(--element-width) + 10%);
        @include mixins.carouselElement();

        .gameImage {
            --game-image-width: 191px;

            width: var(--game-image-width);
            min-width: var(--game-image-width);
            height: var(--game-image-height);
            margin-right: var(--element-gap);
            border-radius: var(--radius-16);
            align-self: center;
        }

        .container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
            color: var(--text-base-primary);
            height: var(--game-image-height);

            .playerContainer {
                display: flex;
                align-items: center;

                .icon {
                    @include mixins.userIcon24();
                }


                .player {
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    @include player16_title();
                }
            }

            .currencyContainer {
                display: flex;
                align-items: center;

                .totalAmount {
                    @include mixins.text20();
                }
            }

            .providerName {
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: -0.24px;
            }

            .totalAmount {
                font-size: 20px;
                color: var(--text-accent-brand-secondary);
            }

            .mobileButton {
                width: 40px;
                max-width: 40px;
                height: 40px;
            }
        }
    }
}

.currency {
    --currency-size: 24px;

    min-width: var(--currency-size);
    height: var(--currency-size);
}

.currencyCode {
    font-size: 20px;
    color: var(--text-accent-brand-secondary);
}

.carousel {
    @include mixins.carouselSettings();
}

@media (min-width: 1440px) and (max-width: 1900px) {
    .winners {
        --element-width: 258px;
        --button-width: 98px;

        .element {
            --element-gap: 8px;
            --game-image-height: 102px;

            .gameImage {
                --game-image-width: 136px;
            }

            .container {

                .playerContainer {

                    .player {
                        @include player12_title();
                    }
                }

                .button {
                    margin-top: 8px;
                }

            }
        }
    }
}

@media #{$mobile} {
    .winners {
        --element-width: 288px;
        //--button-width: 98px;

        .title {
            margin-bottom: var(--standard-small-padding);
        }

        .element {
            --game-image-height: 56px;
            --element-gap: 8px;

            .gameImage {
                --game-image-width: 80px;
            }

            .button {
                width: 40px;
                height: 40px;
                margin-left: auto;
            }

            .container {
                .playerContainer {
                    .player {
                        max-width: 106px;
                    }
                }
            }


        }
    }

    .carousel {
        padding-left: 16px;
    }
}

@media (min-width: 1440px) and (max-width: 1900px), #{$mobile} {
    .currency {
        --currency-size: 16px;
    }

    .winners {
        .element {
            .container {
                .currencyContainer {
                    .totalAmount {
                        @include mixins.text14();
                        line-height: 16px;
                    }
                }

                .providerName {
                    @include mixins.text10();
                }
            }
        }
    }

}

