@use '../../../../common/styles/mixins';

.colorBlock {
    height: var(--color-qustions-block-height);
    width: 100%;
    border-radius: var(--radius-16);
    position: relative;
    cursor: pointer;

    .image {
        position: absolute;
        left: var(--color-block-trophy-offset);
        bottom: var(--color-block-trophy-offset);
    }

    .textContainer {
        padding-left: 84px;


        .title {
            padding-top: 10px;
            @include mixins.ColorQuestionsBlockTitle();

        }
        .subtitle {
            padding-top: 4px;
            @include mixins.ColorQuestionsBlockSubtitle();
        }
    }
}

.wheelOfFortune {
    background: linear-gradient(to right, #6F00FF, var(--background-surface-default));
}

.bonuses {
    background: linear-gradient(to right, #00C40D, var(--background-surface-default));

}

.vipClub {
    background: linear-gradient(to right, #FF8800, var(--background-surface-default));
}