@use '../../../../common/styles/mixins';
@import 'src/common/styles/_adaptive.scss';

.carousel {
    .element {
        width: 134px;
        height: 88px;
        @include mixins.carouselElement();
        @include mixins.flexCenter();
    }
}

.carouselSettings {
    @include mixins.carouselSettings();
}

@media #{$mobile} {
    .carousel {
        .element {
            width: 109px;
            height: 72px;

            img {
                max-width: 99px;
            }
        }
    }
    .carouselSettings {
        gap: 8px;
    }
}