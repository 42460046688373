@forward 'variables/colors';
@forward 'variables/semantic';
@forward 'variables/measurements';
@forward 'themes/theme-light';
@forward 'themes/theme-dark';
@forward 'boilerplate';
@forward 'variables/other';
@forward 'fonts';
@forward 'variables/constants';

* {
    font-family: var(--font-family), sans-serif;
}

body {
    color: var(--text-base-primary);
}