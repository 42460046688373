.brightCheckbox {
    input {
        box-shadow: none;
        border: none;
        background-color: var(--background-surface-elevation-1);
    }

    input:checked {
        background-color: var(--background-button-primary);
        box-shadow: none;
    }

    input:not(:checked) {
        box-shadow: none;
    }

    input:focus {
        border: none; 
    }
}

.brightCheckboxError {
    input {
        border: 1px solid var(--border-accent-red-accent);
    }
}