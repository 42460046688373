.breadcrumbs {
    .separator {
        margin-left: 5px;
        margin-right: 5px;
    }

    .separator, .link, .categoryNameBreadcrumb {
        font-size: 16px;
        font-weight: 600;
        line-height: 18px;
    }

    .separator, .link {
        color: var(--text-base-primary);
    }

    .link {
        text-decoration: none;
    }

    .categoryNameBreadcrumb {
        color: var(--text-base-secondary);
        text-transform: capitalize;
    }

    li {
        margin: 0;
    }
}