@use '../../common/styles/mixins';

.paginationContainer {
    --items-margin: 8px;
    --chevron-margin: 16px;

    display: flex;
    align-items: center;
    list-style-type: none;

    .paginationItem {
        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 40px;
        height: 40px;

        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;

        margin-right: var(--items-margin);

        &:hover {
            cursor: pointer;
        }

    }

    .disabled {
        cursor: auto;

        &:hover {
            cursor: auto;
            background: var(--background-surface-subtle-primary);
        }
    }

    .selected {
        background: var(--background-surface-subtle-primary);
        border-radius: (var(--radius-100));
    }

    .dots:hover {
        cursor: default;
        background-color: transparent;
    }

    .selectBlock {
        display: flex;
        align-items: center;

        .selectContainer {
            width: 80px;
            align-items: center;
        }

        .showLabel,
        .totalLabel {
            display: inline-block;

            font-family: Lato, sans-serif;
            font-size: 12px;
            font-weight: 400;
            line-height: 120%;
        }

        .showLabel {
            margin-right: 5px;
            margin-left: 20px;
        }

        .superSelect {
            width: 50px;
        }

        .totalLabel {
            margin-left: 5px;
            width: 190px;
        }
    }

    .chevronLeft {
       margin-right: var(--chevron-margin);
    }

    .chevronRight {
        margin-left: calc((var(--items-margin) * -1) + var(--chevron-margin)); /*done this way, because last-of-type not works*/
    }
}
