@use 'src/common/styles/mixins';

.popupBackground {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(34deg, #000 0%,#003200 20%, #007000 45%, #007000 55%, #003200 80%, #000 100%);
    border-radius: var(--radius-40);
    background-size: cover;
    background-position: center;
    z-index: 1001;
}

.popupContainer {
    z-index: 1002;
    width: 100%;
    // border-radius: 16px;
    // padding: 24px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    text-align: center;
    color: var(--text-base-primary);
    display: flex;
    flex-direction: column;
    // gap: 16px;
    align-self: stretch;
    justify-content: center;
    gap: 10px;

   
    .checkedImg{
        width: 50px;
        height: 50px;
        align-self: center;
    }

    .textSuccess{
        font-weight: bold;
        font-size: 28px;
    }
    

}
