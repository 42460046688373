@use '../../../../common/styles/mixins';
@import 'src/common/styles/_adaptive.scss';

.leftPart {
    overflow: hidden;
    @include mixins.flex($justifyContent: center , $alignItems: center, $direction: column);
    gap: 8px;
    position: relative;
    max-width: 100%;

    .title, .bottomText, .bonusTitle, .carousel, .leftControl, .rightControl {
        z-index: 1;
    }

    .title, .bottomText {
        @include mixins.title24();
    }

    .bonusTitle {
        @include mixins.title64();
        white-space: break-spaces;
    }

    .carouselContainer {
        @include mixins.flex($alignItems: center, $justifyContent: space-between);
        width: 100%;
        justify-content: center;
        height: fit-content;

        .leftControl {
            margin-left: 5px;
        }

        .rightControl {
            margin-right: 5px;
        }

        .carousel {
            width: calc(100% - 90px);
            text-align: center;
            text-wrap: wrap;
            @include mixins.flex($justifyContent: center);
        }
    }
}

.leftPart::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4); // for opacity image effect
}

@media #{$mobile} {
    .leftPart {
      padding: 16px;

      .bonusTitle {
         @include mixins.title24();
      }

        .title, .bottomText {
            @include mixins.text14();
        }
    }
}
