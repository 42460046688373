@use '../../../../../common/styles/mixins';


.dividerContainer {
    @include mixins.flexCenter();
    gap: 8px;
    margin: var(--auth-modal-big-margin) 0;

    .line {
        flex: 1;
        height: 1px;
        background: var(--text-base-secondary);
    }

    .dividerText {
        color: var(--text-base-secondary);
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.28px;
    }
}

.socialButtons {
    display: flex;
    gap: 10px;

    .button {
        width: 100%;

        img {
            width: 16px;
            height: 16px;
        }
    }
}