@use '../../../../../common/styles/mixins';

.titleAndFilterButton {
    @include mixins.flex($justifyContent: space-between, $alignItems: center);
    padding: 24px;
    

    .title {
        @include mixins.title30();
        margin-bottom: 0;
    }
}