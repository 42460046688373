@use '../../../common/styles/mixins';
@import 'src/common/styles/_adaptive.scss';

.gameGroups {
    --carousel-height: 88px;
    --game-icon-size: 32px;

    height: var(--carousel-height);

    .groupsContainer {
        width: 100%;
        background-color: var(--background-surface-elevation-1);
        padding: 0 12px;
        border-radius: 70px;
    }

    .element {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: var(--background-surface-elevation-2);
        height: 64px;
        border-radius: var(--radius-16);
        font-size: 20px;
        @include mixins.flexCenter();
        padding: 16px 24px;

        .gameIcon {
            width: var(--game-icon-size);
            height: var(--game-icon-size);
            margin-right: 8px;
        }

        .groupName {
            @include mixins.text20();
        }
    }

    .carousel {
        height: var(--carousel-height);
        display: flex;
        align-items: center;
        gap: 6px;
    }
}


@media #{$mobile} {
    .gameGroups {
        --carousel-height: 56px;
        --game-icon-size: 16px;

        .groupsContainer {
            border-radius: unset;
        }

        .element {
            height: 40px;
            border-radius: var(--radius-12);

            .groupName {
                @include mixins.text14_weight600();
            }

            .gameIcon {
                margin-right: 6px;
            }
        }
    }
}

