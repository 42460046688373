@use '../../../../../common/styles/mixins';

.myBonuses {
    grid-column: span 3;
    width: 100%;
    
    .headerContainer {
      @include mixins.flex($justifyContent: space-between);
      margin-bottom: 16px;
    }

    .bonusElements {
        @include mixins.flex($justifyContent: space-between);
        gap: 16px;
    }
}