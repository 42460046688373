.promoCode {
    display: flex;
    justify-content: center;

    .promo {
        cursor: pointer;
        color: var(--text-base-secondary);
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.28px;
        width: fit-content;
    }
}