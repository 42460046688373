@use 'src/common/styles/mixins';
@import 'src/common/styles/_adaptive.scss';

.carousel {
    position: relative;
    display: flex;
    gap: 10px; // changed from figma 16px to fit the design // todo: need to understand why
    height: 180px;

    .carouselControls {
        @include mixins.carouselControlsCenter();

        width: 100%;
        right: 0;
        z-index: 1;
        gap: 10px;

        pointer-events: none;
    }

    .bonusCard {
        position: relative;
        width: 200px;
        min-width: 200px;
        height: 100%;
        border: 2px solid var(--border-base-default);
        border-radius: var(--radius-24);
        background: var(--background-surface-elevation-1);
        padding: 16px;
        cursor: pointer;
        overflow: hidden;

        .bonusHeader, .bonusDescription {
            text-wrap: wrap;
            word-wrap: break-word;
        }


        .bonusHeader {
            font-size: 18px;
            font-weight: 600;
            line-height: 20px;
            margin-bottom: 12px;
        }

        .bonusDescription {

            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.32px;
            color: var(--text-base-secondary);
        }

        .bonusImg {
            position: absolute;
            bottom: 0;
            right: 0;
            max-width: 50%;
            max-height: 50%;
        }
    }

    .bonusCardActive {
        border: 2px solid var(--border-accent-brand-accent);

    }
}

@media #{$mobile} {
    .carousel {
        height: 130px;
        gap: 12px;
        margin-bottom: 16px;

        .bonusCard {
            position: relative;
            width: 158px;
            min-width: 158px;

            .bonusHeader {
                @include mixins.text14_weight600();
                color: var(--text-base-primary);
            }
        }
    }
}