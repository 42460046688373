@use '../../../../common/styles/mixins';

.button {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
}
.form{
    padding: 0 0.5rem 1rem 0.5rem;
}

.promoContainer {
    display: flex;
    justify-content: center;

    .promo {
        cursor: pointer;
        color: var(--text-base-secondary);
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.28px;
        margin-top: 16px;
        width: fit-content;
    }
}