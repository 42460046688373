@import 'src/common/styles/_adaptive.scss';

@mixin flexCenter() {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex($direction: row, $justifyContent: flex-start , $alignItems: flex-start, $wrap: nowrap) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justifyContent;
    align-items: $alignItems;
    flex-wrap: $wrap;
}

@mixin carouselElement() {
    background-color: var(--background-surface-elevation-2);
    border-radius: var(--radius-24);

    @media #{$mobile} {
        border-radius: var(--radius-16);
    }
}

@mixin carouselSettings() {
    display: flex;
    gap: 24px;

    @media (min-width: 1440px) and (max-width: 1900px) {
        gap: 16px;
    }

    @media #{$mobile} {
        gap: 16px;
    }
}

@mixin h5Group() {
    margin-bottom: 0;
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -0.72px;

    @media (min-width: 1440px) and (max-width: 1900px) {
        font-size: 30px;
        line-height: 36px;
        letter-spacing: -0.6px;
    }

    @media #{$mobile} {
       @include text18();
    }
}

@mixin sidebarItemFontStyles() {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.32px;
}

@mixin blockBottomMargin() {
    margin-bottom: 80px;

    @media #{$mobile} {
        margin-bottom: 40px;
    }
}

@mixin blockBottomMarginMobile24() {
    margin-bottom: 24px;
}

@mixin ColorQuestionsBlockTitle() {
    color: var(--text-base-primary);
    font-size: 14px;
}

@mixin ColorQuestionsBlockSubtitle() {
    color: var(--text-base-secondary);
    font-size: 12px;
}

@mixin SidebarElementsContainer() {
    display: flex;
    flex-direction: column;
}

@mixin itemHover() {
    background-color: var(--background-tabs-normal);
    color: var(--text-accent-brand-primary);
    cursor: pointer;
}

@mixin userIcon24() {
    --user-icon-size: 24px;

    height: var(--user-icon-size);
    min-width: var(--user-icon-size);
    margin-right: 8px;

    @media (min-width: 1440px) and (max-width: 1900px), #{$mobile} {
        --user-icon-size: 16px;
    }
}

@mixin gameGroup($margin-top: 40px, $padding: var(--game-group-padding)) {
    @if $margin-top != null {
        margin-top: $margin-top;
    }
    padding: $padding;
    background-color: var(--background-surface-elevation-1);
    border-radius: var(--radius-40);
}

@mixin gameGroupMobile {
    margin-top: 0;
    padding: var(--mobile-element-padding);
    border-radius: 0;
    background-color: var(--background-surface-default);
}

@mixin gameCategoryName() {
    @include title30();
    text-transform: capitalize;
    margin-left: 24px;
}

@mixin buttonGlassHoverActive() {
    &:hover {
        background-color: var(--background-button-glass-hover);
    }

    &:active {
        background-color: var(--background-button-glass-pressed-correct);
    }
}

@mixin input() {
    height: 40px;
    border-radius: var(--radius-12);
    border: 1px solid var(--border-action-normal);

    &:focus {
        outline: none;
        box-shadow: none;
    }

}

@mixin hintVariant {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.28px;
    color: var(--text-base-secondary);
}

@mixin profileText() {

}

@mixin moneyType() {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.24px;
    margin-bottom: 0;
    padding-bottom: 0;

    @media #{$mobile} {
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: unset;
    }

}

@mixin elementName {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
}

@mixin text10() {
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
}

@mixin text12() {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
}

@mixin text12_width400() {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;
}

@mixin text14() {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.28px;
}

@mixin text14_weight600() {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
}

@mixin text16() {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

@mixin text16_weight600() {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.32px;
}

@mixin text16_mobile_bonus {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
}

@mixin text18() {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
}

@mixin text20() {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.4px;
}

@mixin title24() {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.48px
}

@mixin title30() {
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.6px;
}

@mixin title64() {
    font-size: 64px;
    font-weight: 700;
    line-height: 72px;
    letter-spacing: -1.28px;
}

@mixin carouselControlsCenter() {
    position: absolute;
    display: flex;
    top: 50%;
    transform: translateY(-50%);
    justify-content: space-between;
}

// For Deposit & AddWallet
@mixin currencyButton() {
    background-color: var(--background-surface-elevation-2);
    border: 1px solid var(--border-base-default);

    @include buttonGlassHoverActive(); /*Done same styles hover, active as for glass button*/
}
@mixin buttonGroup() {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}



