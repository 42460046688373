@use 'src/common/styles/mixins';

.fileUpload {
    @include mixins.flex($alignItems: center);
    gap: 10px;

    .hiddenFileInput {
        opacity: 0;
        position: absolute;
        width: 0;
        height: 0;
    }
}
