*,
*::before,
*::after {
    box-sizing: border-box;
}

input,
button,
select,
textarea,
optgroup,
option {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-style: inherit;
    color: inherit;
}


