:root {
  /* Semantic - Mode 1 */
  --semantic-neutral-0: var(--colour-base-white);
  --semantic-inverted-0: var(--colour-base-black);
  --semantic-inverted-1: var(--colour-gray-14);
  --semantic-inverted-2: var(--colour-gray-13);
  --semantic-inverted-3: var(--colour-gray-12);
  --semantic-inverted-4: var(--colour-gray-11);
  --semantic-inverted-5: var(--colour-gray-10);
  --semantic-inverted-6: var(--colour-gray-9);
  --semantic-inverted-7: var(--colour-gray-8);
  --semantic-inverted-8: var(--colour-gray-7);
  --semantic-inverted-9: var(--colour-gray-6);
  --semantic-inverted-10: var(--colour-gray-5);
  --semantic-inverted-11: var(--colour-gray-4);
  --semantic-inverted-12: var(--colour-gray-3);
  --semantic-inverted-13: var(--colour-gray-2);
  --semantic-inverted-14: var(--colour-gray-1);
  --semantic-inverted-0a: var(--colour-white-0a);
  --semantic-inverted-1a: var(--colour-white-1a);
  --semantic-inverted-2a: var(--colour-white-2a);
  --semantic-inverted-3a: var(--colour-white-3a);
  --semantic-inverted-4a: var(--colour-white-4a);
  --semantic-inverted-5a: var(--colour-white-5a);
  --semantic-inverted-6a: var(--colour-white-6a);
  --semantic-inverted-7a: var(--colour-white-7a);
  --semantic-inverted-8a: var(--colour-white-8a);
  --semantic-informative-1: var(--colour-blue-1);
  --semantic-positive-1: var(--colour-green-1);
  --semantic-warning-1: var(--colour-orange-1);
  --semantic-warning-2: var(--colour-orange-2);
  --semantic-warning-1a: var(--colour-orange-1a);
  --semantic-warning-2a: var(--colour-orange-2a);
  --semantic-negative-1: var(--colour-red-1);
  --semantic-brand-1-1: var(--colour-toxic-1);
  --semantic-negative-2: var(--colour-red-2);
  --semantic-negative-1a: var(--colour-red-1a);
  --semantic-negative-2a: var(--colour-red-2a);
  --semantic-positive-2: var(--colour-green-2);
  --semantic-positive-1a: var(--colour-green-1a);
  --semantic-positive-2a: var(--colour-green-2a);
  --semantic-informative-2: var(--colour-blue-2);
  --semantic-informative-1a: var(--colour-blue-1a);
  --semantic-informative-2a: var(--colour-blue-2a);
  --semantic-neutral-1: var(--colour-gray-1);
  --semantic-neutral-2: var(--colour-gray-2);
  --semantic-neutral-3: var(--colour-gray-3);
  --semantic-neutral-4: var(--colour-gray-4);
  --semantic-neutral-5: var(--colour-gray-5);
  --semantic-neutral-6: var(--colour-gray-6);
  --semantic-neutral-7: var(--colour-gray-7);
  --semantic-neutral-8: var(--colour-gray-8);
  --semantic-neutral-9: var(--colour-gray-9);
  --semantic-neutral-10: var(--colour-gray-10);
  --semantic-neutral-11: var(--colour-gray-11);
  --semantic-neutral-12: var(--colour-gray-12);
  --semantic-neutral-13: var(--colour-gray-13);
  --semantic-neutral-14: var(--colour-gray-14);
  --semantic-neutral-0a: var(--colour-white-0a);
  --semantic-neutral-1a: var(--colour-gray-1a);
  --semantic-brand-1-2: var(--colour-toxic-2);
  --semantic-neutral-2a: var(--colour-gray-2a);
  --semantic-neutral-3a: var(--colour-gray-3a);
  --semantic-neutral-4a: var(--colour-gray-4a);
  --semantic-neutral-5a: var(--colour-gray-5a);
  --semantic-neutral-6a: var(--colour-gray-6a);
  --semantic-neutral-7a: var(--colour-gray-7a);
  --semantic-neutral-8a: var(--colour-gray-8a);
  --semantic-brand-1-3: var(--colour-toxic-3);
  --semantic-brand-1-4: var(--colour-toxic-4);
  --semantic-brand-1-1a: var(--colour-toxic-1a);
  --semantic-brand-1-2a: var(--colour-toxic-2a);
}
