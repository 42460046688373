.component {
    --chips-left-size: calc((100vh - var(--navbar-height)) * 0.58);
    --chips-right-size: calc((100vh - var(--navbar-height)) * 0.58);

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    position: relative;

   .numbers {
       display: flex;
       gap: 45.22px;

       img {
           height: 418px;
           width: 337px;
       }
   }

    .notFound, .text {
        color: var(--text-base-primary);
    }
    
    .notFound {
        text-align: center;
        font-size: 64px;
        font-weight: 700;
        line-height: 72px;
        letter-spacing: -1.28px;
        padding-top: 72px;
    }
    
    .text {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.32px;
        margin-top: 40px;
    }

    .button {
        margin-top: 40px;
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
    }

    .clubs_poker_chip, .diamonds_poker_chip {
        position: absolute;
    }

    .clubs_poker_chip {
        top: 60%;
        left: -10%;
        width: var( --chips-left-size);
        height: var(--chips-left-size);
        opacity: 0.4;
    }

    .diamonds_poker_chip {
        right: -10%;
        bottom: 20%;
        opacity: 0.4;
        width: var(--chips-right-size);
        height: var(--chips-right-size);
    }
}