.dark-theme {
  --border-base-default: var(--semantic-inverted-4);
  --background-surface-default: var(--semantic-inverted-0);
  --border-action-normal: var(--semantic-inverted-2a);
  --background-button-primary: var(--semantic-brand-1-3);
  --background-chip-primary: var(--semantic-inverted-14);
  --background-chip-secondary: var(--semantic-inverted-3a);
  --background-chip-tetriary: var(--semantic-inverted-4a);
  --background-chip-success-subtle: var(--semantic-positive-1a);
  --background-button-primary-pressed: var(--semantic-brand-1-4);
  --background-button-secondary: var(--semantic-brand-1-3);
  --background-chip-success-accent: var(--semantic-positive-1);
  --background-chip-info-subtle: var(--semantic-informative-1a);
  --background-chip-info-accent: var(--semantic-informative-1);
  --background-chip-brand-1: var(--semantic-brand-1-3);
  --background-button-secondary-pressed: var(--semantic-warning-1);
  --background-button-disabled: var(--semantic-inverted-2a);
  --background-button-custom-disabled: var(--semantic-inverted-12);
  --background-button-glass: var(--semantic-inverted-2a);
  --background-button-glass-pressed: var(--semantic-inverted-3a);
  --background-button-ghost: var(--semantic-inverted-0a);
  --background-surface-elevation-1: var(--semantic-inverted-1);
  --background-surface-elevation-2: var(--semantic-inverted-2);
  --background-surface-elevation-3: var(--semantic-inverted-3);
  --icon-base-primary: var(--semantic-inverted-14);
  --icon-base-secondary: var(--semantic-inverted-7a);
  --text-base-primary: var(--semantic-inverted-14);
  --text-status-destructive: var(--semantic-negative-1);
  --text-accent-brand-primary: var(--semantic-brand-1-3);
  --text-accent-brand-secondary: var(--semantic-brand-1-2);
  --text-accent-blue: var(--semantic-informative-1);
  --text-status-destructive-secondary: var(--semantic-negative-1a);
  --text-status-success: var(--semantic-positive-1);
  --text-status-success-secondary: var(--semantic-positive-1a);
  --text-status-info: var(--semantic-informative-1);
  --text-status-info-secondary: var(--semantic-informative-1a);
  --text-status-warning: var(--semantic-warning-1);
  --text-status-warning-secondary: var(--semantic-warning-1a);
  --text-base-secondary: var(--semantic-inverted-7a);
  --text-base-tetriary: var(--semantic-inverted-4a);
  --text-base-quarternary: var(--semantic-inverted-3a);
  --text-base-inverted: var(--semantic-inverted-0);
  --text-base-inverted-secondary: var(--semantic-neutral-7a);
  --text-base-inverted-tetriary: var(--semantic-neutral-2a);
  --icon-status-destructive: var(--semantic-negative-1);
  --icon-status-destructive-secondary: var(--semantic-negative-1a);
  --icon-status-success: var(--semantic-positive-1);
  --icon-status-success-secondary: var(--semantic-positive-1a);
  --icon-status-info: var(--semantic-informative-1);
  --icon-status-info-secondary: var(--semantic-informative-1a);
  --icon-status-warning: var(--semantic-warning-1);
  --icon-status-warning-secondary: var(--semantic-warning-1a);
  --icon-base-inverted: var(--semantic-neutral-14);
  --icon-base-disabled: var(--semantic-inverted-3a);
  --icon-accent-brand-primary: var(--semantic-brand-1-3);
  --icon-accent-brand-secondary: var(--semantic-brand-1-2);
  --icon-accent-blue: var(--semantic-informative-1);
  --border-base-primary: var(--semantic-inverted-14);
  --border-base-secondary: var(--semantic-inverted-7a);
  --border-base-tetriary: var(--semantic-inverted-3a);
  --background-surface-subtle-primary: var(--semantic-inverted-2a);
  --background-surface-subtle-secondary: var(--semantic-inverted-3a);
  --background-surface-inverted: var(--semantic-neutral-0);
  --background-surface-inverted-subtle: var(--semantic-inverted-8a);
  --background-surface-destructive-subtle: var(--semantic-negative-1a);
  --background-surface-destructive-accent: var(--semantic-negative-1);
  --background-surface-warning-subtle: var(--semantic-warning-1a);
  --background-surface-warning-accent: var(--semantic-warning-1);
  --background-surface-success-subtle: var(--semantic-positive-1a);
  --background-surface-success-accent: var(--semantic-positive-1);
  --background-surface-info-subtle: var(--semantic-informative-1a);
  --background-surface-info-accent: var(--semantic-informative-1);
  --border-action-disabled: var(--semantic-inverted-3a);
  --border-action-ghost: var(--semantic-inverted-5a);
  --border-action-primary: var(--semantic-informative-1);
  --border-action-primary-pressed: var(--semantic-informative-2);
  --border-action-destructive: var(--semantic-negative-1);
  --border-action-success: var(--semantic-positive-1);
  --border-action-brand-1: var(--semantic-brand-1-3);
  --background-surface-brand-primary: var(--semantic-brand-1-1);
  --border-accent-blue: var(--semantic-informative-1a);
  --border-accent-blue-accent: var(--semantic-informative-1);
  --border-accent-green: var(--semantic-positive-1a);
  --border-accent-green-accent: var(--semantic-positive-1);
  --border-accent-orange: var(--semantic-warning-1a);
  --border-accent-orange-accent: var(--semantic-warning-1);
  --border-accent-red: var(--semantic-negative-1a);
  --border-accent-red-accent: var(--semantic-negative-1);
  --border-accent-brand: var(--semantic-brand-1-1a);
  --border-accent-brand-accent: var(--semantic-brand-1-3);
  --background-modal-primary: var(--semantic-inverted-1);
  --background-modal-secondary: var(--semantic-inverted-2);
  --background-modal-tetriary: var(--semantic-inverted-3);
  --background-modal-quarternary: var(--semantic-inverted-5);
  --background-overlay-primary: var(--semantic-inverted-4a);
  --background-overlay-secondary: var(--semantic-inverted-2a);
  --background-overlay-tetriary: var(--semantic-inverted-1a);
  --background-overlay-inverted: var(--semantic-neutral-7a);
  --icon-action-primary: var(--semantic-informative-1);
  --icon-action-primary-pressed: var(--semantic-informative-2);
  --icon-action-secondary: var(--semantic-brand-1-3);
  --icon-action-secondary-pressed: var(--semantic-warning-1);
  --text-action-primary: var(--semantic-informative-1);
  --text-action-primary-pressed: var(--semantic-informative-2);
  --text-action-secondary: var(--semantic-brand-1-3);
  --text-action-secondary-pressed: var(--semantic-warning-1);
  --background-button-brand-1: var(--semantic-brand-1-1);
  --background-input-normal: var(--semantic-inverted-1);
  --background-input-hover: var(--semantic-inverted-2);
  --background-input-disabled: var(--semantic-inverted-0);
  --background-tabs-normal: var(--semantic-inverted-2a);
}
