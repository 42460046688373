@use 'src/common/styles/mixins';

.bonuses {
    .title {
        @include mixins.title64();
        padding-bottom: 40px;
    }

    .navigationContainer {
        margin-bottom: 40px;
    }
    
    .bonusElements {
        display: grid;
        grid-template-columns: repeat(2, minmax(710px, 748px));
        padding: 24px;
        column-gap: 16px;
        row-gap: 24px;
        background-color: var(--background-surface-elevation-1);
        border-radius: 40px;
    }
}

