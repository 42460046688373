.transactionsContainer {
    width: 100%;
}

.filtersContainer {
    width: 100%;
    margin-bottom: 16px;
}

.tableContainer {
    width: 100%;
    //margin-bottom: 16px;
    //padding: 16px;
    //border-radius: 8px;
}
