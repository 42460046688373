:root {
    /* Measurements - Mode 1 */
    --numbers-2: 2px;
    --numbers-4: 4px;
    --numbers-8: 8px;
    --numbers-12: 12px;
    --numbers-16: 16px;
    --numbers-20: 20px;
    --numbers-24: 24px;
    --numbers-32: 32px;
    --numbers-40: 40px;
    --numbers-48: 48px;
    --numbers-56: 56px;
    --numbers-64: 64px;
    --numbers-72: 72px;
    --numbers-80: 80px;
    --numbers-88: 88px;
    --numbers-96: 96px;
    --numbers-100: 100px;

    --radius-0: var(--numbers-0);
    --radius-4: var(--numbers-4);
    --radius-8: var(--numbers-8);
    --radius-12: var(--numbers-12);
    --radius-16: var(--numbers-16);
    --radius-24: var(--numbers-24);
    --radius-32: var(--numbers-32);
    --radius-40: var(--numbers-40);
    --radius-100: var(--numbers-100);

    --grid-none: var(--numbers-0);
    --grid-small: var(--numbers-4);
    --grid-normal: var(--numbers-8);
    --grid-medium: var(--numbers-12);
    --grid-large: var(--numbers-16);
    --grid-huge: var(--numbers-24);
    --grid-enormous: var(--numbers-32);
    --grid-gigantic: var(--numbers-40);
    --grid-colossal: var(--numbers-48);
    --grid-massive: var(--numbers-56);
    --grid-immense: var(--numbers-64);
}

