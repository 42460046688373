@use './mixins';
@import './adaptive';

@mixin title() {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.4px;

    @media #{$mobile} {
        @include mixins.text12();
    }
}

@mixin body() {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.32px;
    margin: 0;

    @media #{$mobile} {
        @include mixins.text12_width400();
    }
}

@mixin meta_description() {
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -0.72px;

    @include mixins.text18();
}

@mixin contentText() {
    max-width: 45%;
    max-height: 144px;

    @media #{$mobile} {
       max-width: unset;
       max-height: unset;
    }
}

@mixin contentImage() {
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: right;
    }
}