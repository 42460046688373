@import 'src/common/styles/_adaptive.scss';

.childrenMenu {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 100%;
    transition: transform 0.3s ease;
    width: auto;
    height: auto;
    background-color: var(--background-surface-elevation-1);
    color: var(--text-base-secondary);
    border-radius: var(--radius-24);
    margin-left: 9px;
    border: 1px solid var(--border-base-default);
    padding: var(--sidebar-children-menu-padding);

    .childrenItemsContainer {
        position: relative;
        gap: var(--sidebar-children-items-gap);


        .tippy {
            position: absolute;
            height: 26px;
            width: 10px;
            top: 50%;
            left: calc(-1 * var(--sidebar-children-menu-padding) - 10px);
            transform: translateY(-50%);
            border-right: none;

        }
    }
}

.open {
    transform: translateX(0);
}

.closed {
    transform: translateX(calc(-100% - 10px));
}


