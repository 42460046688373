@use 'src/common/styles/mixins';

.documents {
    background-color: var(--background-surface-elevation-1);
    display: flex;
    padding: var(--standart-block-padding);
    gap: var(--standart-block-padding);

    .upload {
        background-color: var(--background-surface-elevation-2);
        height: fit-content;
        padding: var(--standart-block-padding);
        border-radius: var(--radius-24);
        max-width: 366px;

        .title {
            @include mixins.title24();
        }

        .selectContainer {
            margin-top: var(--standart-block-padding);
        }

        .text {
            @include mixins.text14();

        }

        .button {
            margin-top: var(--standart-block-padding);
        }

    }

    .yourDocuments {
        @include mixins.flex($direction: column);
        padding: 0;
        width: 100%;

        .yourDocumentsTitle {
            @include mixins.title24();
            margin: 0;
        }

        .documentsContainer {
            @include mixins.flex($wrap: wrap);
            margin-top: var(--standart-block-padding);
            gap: 10px; /*changed from Figma 16px to include 5 columns on 1080p*/
        }
    }


}