.loginNavigationButtonsGroup {
    display: flex;
    gap: 8px;
    background: var(--background-surface-elevation-2);
    border-radius: var(--radius-32);
    padding: 4px;

    .navigationButton {
        width: 100%;
        border-radius: var(--radius-100);
    }
}