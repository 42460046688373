@use 'src/common/styles/mixins';
@import 'src/common/styles/_adaptive.scss';

.search {
   position: relative;
   overflow: hidden;
   --border-action-normal: rgba(43, 43, 43); // hardcode color without alpha channel
   border-radius: var(--radius-12);

   &::before {
      content: "";
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      background-image: var(--icon, url("../../assets/images/icons/search_md.svg"));
      background-repeat: no-repeat;
      background-size: contain;
   }

   &.iconRight::before {
      left: auto;
      right: 10px; // Icon on the right
   }

   .input {
      @include mixins.input();
      padding: 9px 10px;
      background-color: var(--background-surface-elevation-1);
      overflow: hidden;
      background-clip: padding-box;
   }

   .inputWithIcon {
      padding: 9px 10px 9px 38px;
   }

   .inputWithIconRight {
      padding: 9px 38px 9px 10px;

   }

   .inputSearch {
      width: var(--list-balances-dropdown-width);
   }

   .inputAuth, .inputProfile, .inputSearchAddWallet {
      width: 100%;
   }

   .inputSearchAddWallet, .inputSearchAddWallet::placeholder {
      @include mixins.text14();

   }

   .inputSearchAddWallet {
      margin: 24px 0; 
   }

   .inputSearchAddWallet::placeholder {
      color: var(--text-base-tetriary);
   }

   .inputAuth {
      color: var(--text-base-primary);
   }

   .inputProfile {
      color: var(--text-base-tetriary);
      background-color: var(--background-input-normal);

      &:disabled {
         background: var(--background-input-disabled-correct);
         border: 1px solid var(--border-action-disabled-correct);
         cursor: not-allowed;
      }
   }

   .eye {
        position: absolute;
        right: 10px;
        top: 50%;
        cursor: pointer;
   }

   .eyePositionShift {
      transform: translateY(-50%);
   }

   .eyePassive {
      cursor: auto;
   }

   // autofill styles
   input:-webkit-autofill,
   input:-webkit-autofill:hover,
   input:-webkit-autofill:focus {
      box-shadow: 0 0 0 1000px var(--background-surface-elevation-1) inset,
      0 0 0 1px var(--border-action-normal) inset; 
      border-radius: var(--radius-12);
      -webkit-text-fill-color: var(--text-base-primary);
      background-clip: padding-box;
      caret-color: var(--text-base-primary);
   }



   .errorWrapper {
      @include mixins.flex($alignItems: center);
      gap: 4px;

      .error {
         font-size: 14px;
         font-weight: 400;
         line-height: 22px;
         letter-spacing: -0.28px;
         color: var(--text-status-destructive);
      }
   }
}

@media #{$mobile} {
   .search {
      .inputSearch {
         width: 100%;
      }
   }
}