@use 'src/common/styles/mixins';

.tabbarElement {
    cursor: pointer;
    @include mixins.flex($direction: column, $justifyContent: center, $alignItems: center);
    padding-top: 4px;
    
    .icon {

    }

    .label {
        color: #ffffff;
        @include mixins.text10();
    }
}