@use '../../../common/styles/mixins';
@import 'src/common/styles/_adaptive.scss';

.bonuses {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 24px;
    margin-top: var(--margin-between-blocks);

    .item {
        flex: 1;
        height: 300px;
        @include mixins.flexCenter();
        flex-direction: column;
        gap: 10px;
        border-radius: var(--radius-32);
        cursor: pointer;

        &:nth-child(1) {
            background: linear-gradient(45deg, #FF0090 0%, #FFDD32 100%);
        }

        &:nth-child(2) {
            background: linear-gradient(45deg, #4800FF 0%, #16C1FF 100%);
        }

        &:nth-child(3) {
            background: linear-gradient(45deg, #3C00AB 0%, #CC32FF 100%);
        }

        .title {
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px; /* 133.333% */
            letter-spacing: -0.48px;
        }
    }
}

@media #{$mobile} {
    .bonuses {
        width: unset;
        flex-direction: column;
        margin: 40px 16px;
        gap: 16px;

        .item {
            border-radius: var(--radius-16);
            gap: 8px;
            flex-direction: row;
            height: 64px;
            min-height: 64px;

            img {
                width: 40px;
                height: 40px;
            }
        }
    }
}