@use 'src/common/styles/mixins';

.component {
    color: var(--text-base-primary);

    .paySystem {
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
        align-items: center;
        gap: 8px;

        .currencyImg {

        }

        .paySystemLogo {
            width: 88px;
            height: 40px;
        }
    }


    .submitButton {
        width: 100%;
        margin-top: 16px;
    }

    .amountButtonsGroup {
        display: flex;
        gap: 8px;
        margin-top: 24px;

        .amountButton {
            width: 100%;
            border-radius: var(--radius-8);
        }
    }
}