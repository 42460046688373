@use 'src/common/styles/mixins';
@import 'src/common/styles/_adaptive.scss';

.passwordRecovery {
    color: var(--text-base-secondary);

    --right-part: 496px;

    height: 436px;
    width: fit-content;

    .btnPrev {
        display: flex;
        justify-content: center;
        align-items: center;
        width: var(--modal-action-button-size);
        height: var(--modal-action-button-size);
        color: #fff;
        background-color: var(--background-surface-subtle-primary);
        border: none;
        border-radius: 50%;
        position:absolute;
        left: -3rem;
        top: 4px;
        font-size: 2rem;
        cursor: pointer;
        transition: opacity 0.3s;
    }

    .header{
        color: #fff;
        text-align: center;
        margin-bottom: 40px;
    }

    .text{
        text-align: center;
    }

    .gridContainer {
       display: grid;
       grid-template-columns: var(--login-register-left-part) var(--right-part);
        height: 100%;

        .leftContent {
            height: 100%;
        }

       .rightContent {
       }
    }

    .formInputs {
       display: flex;
       flex-direction: column;
       gap: var(--auth-modal-small-margin);
       margin: var(--auth-modal-big-margin) 0;
    }

    .forgotPasswordContainer {
        margin-top: var(--auth-modal-small-margin);
        cursor: auto;
        display: flex;
        justify-content: center;

        .forgetText {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.28px;
            cursor: pointer;
        }
    }
}

@media #{$mobile} {
    .passwordRecovery {
        padding: 16px;

        .gridContainer {
            grid-template-columns: auto;
            height: unset;
            min-height: 100%;

            .rightContent {
                place-self: center;
            }
        }
    }
}