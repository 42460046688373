@use 'src/common/styles/mixins';

.accountFavouriteGame {
    background: var(--background-surface-elevation-1);
    padding: 8px;
    border-radius: var(--radius-16);
    display: flex;
    gap: 12px;
    align-items: center;

    .gameImg {
        min-width: 80px;
        width: 80px;
        height: 56px;
        border-radius: var(--radius-8);
    }

    .rightContainer {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .gameInfo {
            .gameName {
                @include mixins.elementName();
            }

            img {
                width: 16px;
                height: 16px;
            }

            .provider {
                overflow: hidden;
                color: var(--text-base-secondary);
                text-overflow: ellipsis;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: -0.28px;
            }
        }

        .button {
            width: 40px;
            height: 40px;
        }

        .arrowRight {
            width: 16px;
            height: 16px;
        }
    }


}