@use 'src/common/styles/mixins';
@import 'src/common/styles/_adaptive.scss';

.signup {
    position: relative;
    color: var(--text-base-secondary);
    overflow: hidden;

    .signupFormInputs {
        margin: var(--auth-modal-big-margin) 0;
    }

    .smallMarginBottom {
        margin-bottom: var(--auth-modal-small-margin);
    }

    .bigMarginBottom {
        margin-bottom: var(--auth-modal-big-margin);
    }

    .subscriptionBlock {
        margin-bottom: 8px;
    }
    
    .gridContainer {
        display: grid;
        grid-template-columns: var(--login-register-left-part) 594px;
        height: 100%;
    }

    .gridContainerStage2 {
        display: grid;
        grid-template-columns: var(--login-register-left-part) 454px;
    }
}

.outsideButtonsBlock {
    position: absolute;
    top: calc(var(--modal-action-button-size) + var(--auth-modal-big-margin));
    right: 0;
    transform: translateX(100%); /*on full size of inner item*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.extraBonusTitle {
   text-align: center;
   padding-bottom: var(--auth-modal-big-margin);
}

@media #{$mobile} {
    .signup {
        padding: 16px;
        min-height: calc(100vh - var(--mobile-status-bar-height));
        height: unset;
        display: flex;
        flex-direction: column;
        
        .gridContainer {
            grid-template-columns: auto;
            grid-template-rows: auto auto;
        }
    }
}